@mixin roboto-condensed-regular {
    font-family: 'roboto-condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
}
@mixin roboto-condensed-bold {
    font-family: 'roboto-condensed', sans-serif;
    font-weight: 600;
    font-style: normal;
}
@mixin roboto-regular {
    font-family: 'roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
}
@mixin roboto-regular-italic {
    font-family: 'roboto', sans-serif;
    font-weight: 400;
    font-style: italic;
}
@mixin roboto-light {
    font-family: 'roboto', sans-serif;
    font-weight: 300;
    font-style: normal;
}
@mixin roboto-light-italic {
    font-family: 'roboto', sans-serif;
    font-weight: 300;
    font-style: italic;
}
@mixin roboto-semibold {
    font-family: 'roboto', sans-serif;
    font-weight: 600;
    font-style: normal;
}
@mixin roboto-semibold-italic {
    font-family: 'roboto', sans-serif;
    font-weight: 600;
    font-style: italic;
}
@mixin roboto-bold {
    font-family: 'roboto', sans-serif;
    font-weight: 700;
    font-style: normal;
}
@mixin roboto-bold-italic {
    font-family: 'roboto', sans-serif;
    font-weight: 700;
    font-style: italic;
}
@mixin fz18 {
    font-size: 18px;
    line-height: 28px;
}
@mixin fz17 {
    font-size: 17px;
    line-height: 27px;
}
@mixin fz16 {
    font-size: 16px;
    line-height: 26px;
}
@mixin fz15 {
    font-size: 15px;
    line-height: 24px;
}
@mixin fz14 {
    font-size: 14px;
    line-height: 22px;
}
@mixin pseudo {
    content: '';
    display: block;
    position: absolute;
}
@mixin size-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
@mixin none-size-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
@mixin size-horizontal-center {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
@mixin none-size-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
@mixin size-vertical-center {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
@mixin none-size-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
@mixin rotate-45-left {
    transform: rotate(-45deg);
}
@mixin rotate-135-right {
    transform: rotate(135deg);
}
@mixin flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
@mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-between-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin flex-between-end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
@mixin flex-around-center {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
@mixin flex-start-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@mixin flex-end-start {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
@mixin flex-start-stretch {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
@mixin flex-between-stretch {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
@mixin ofi-cover-ct {
    object-fit: cover;
    object-position: center 0;
    font-family: 'object-fit: cover; object-position: center top';
}
@mixin ofi-cover-lt {
    object-fit: cover;
    object-position: 0 0;
    font-family: 'object-fit: cover; object-position: left top';
}
@mixin ofi-cover-cc {
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center';
}
@mixin ofi-contain-lt {
    object-fit: contain;
    object-position: 0 0;
    font-family: 'object-fit: contain; object-position: left top';
}
@mixin ofi-contain-cc {
    object-fit: contain;
    object-position: center center;
    font-family: 'object-fit: contain; object-position: center center';
}
@mixin bg-center-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
@mixin bg-center-contain {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
@mixin flip-h {
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
}
@mixin flip-v {
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: 'FlipV';
}
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        width: 100%;
        font-size: 0;
        line-height: 0;
        clear: both;
    }
}
@mixin mdl-wrap-full-screen {
    &:before {
        display: none;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
@mixin mdl-full-screen {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 100vh;
    width: 100vw !important;
}
@mixin toe {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
@mixin rlg {
    background: repeating-linear-gradient(
        to right,
        $c-form-item-border 0,
        $c-form-item-border 5px,
        transparent 5px,
        transparent 10px
    );
}
@mixin rlg-disabled {
    background: repeating-linear-gradient(
        to right,
        $c-form-item-border-disabled 0,
        $c-form-item-border-disabled 5px,
        transparent 5px,
        transparent 10px
    );
}
