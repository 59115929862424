.btn-alt {
    position: relative;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    min-height: 36px;
    padding: 0 7px;
    border: none;
    font-size: 16px;
    line-height: 22px;
    @include roboto-condensed-regular;
    background-color: transparent;
    color: $c-font-active;
    &:not(:disabled):hover {
        color: $c-font-active-hover;
        .btn-alt__icon {
            color: $c-font-active-hover;
        }
    }
    &:disabled {
        cursor: auto;
        color: $c-btn-alt-font-disabled;
        .btn-alt__icon {
            color: $c-btn-alt-font-disabled;
        }
    }
}
.btn-alt--icon {
    padding-left: 44px;
}
.btn-alt__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 36px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    color: $c-font-active;
}
.btn-alt--danger {
    &:not(:disabled):hover {
        color: $c-btn-alt-danger-font-hover;
    }
}
.btn-alt--drop {
    padding-right: 21px;
    &:after {
        @include pseudo;
        @include size-vertical-center;
        right: 0;
        height: 6px;
        width: 10px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid $c-btn-alt-drop-arrow;
        transition: transform 0.25s;
    }
    &.active:after {
        transform: rotate(180deg);
    }
}
.side-menu {
    .btn-alt {
        display: flex;
        width: 100%;
    }
    .btn-alt + .btn-alt {
        margin-top: 10px;
    }
}
