.link-txt {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    padding: 0;
    color: $c-font-active;
    background-color: $c-transparent;
    &:hover {
        color: $c-font-active-hover;
    }
}
.link-txt--icon-left {
    padding-left: 32px;
}
.link-txt--icon-right {
    padding-right: 32px;
}
.txt {
    .link-txt:not(.link-txt--icon-left):not(.link-txt--icon-right) {
        margin-left: 5px;
        margin-right: 5px;
    }
    .link-txt--icon-left {
        margin-left: 5px;
    }
    .link-txt--icon-right {
        margin-right: 8px;
    }
}
.type-2 {
    .accordion__body {
        .link-txt {
            display: block;
        }
        .link-txt + .link-txt {
            margin-top: 17px;
        }
    }
}
