.replies-section {
    background-color: #fff;
    display: none;
}

.replies-section-header {
    position: relative;
    @include flex-start-center;
    height: 33px;
    padding: 0 46px 0 20px;
    overflow: hidden;
    border-top: 1px solid $c-border-alt;
    border-bottom: 1px solid $c-border-alt;

    .cross {
        @include size-vertical-center;
        right: 17px;
    }
}

.replies-section__title {
    font-family: 'roboto-condensed';
    font-size: 16px;
    line-height: 22px;
    color: $c-border-alt;

    @media all and (width < 960px) {
        font-size: 15px;
        line-height: 20px;
    }
}

.replies-section-body {
    padding: 0 20px 20px;
}

.replies-area {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        width: 8px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 4px;
    }

    &.scroll-visible {
        padding-right: 8px;
    }
}

.replies-area__item {
    @include flex-start-start;
    min-width: 100%;
    margin-top: 12px;
    &--last {
        margin-bottom: 12px;
    }
    &--received {
    }

    &--sent {
        justify-content: flex-end;
    }

    @media all and (width < 960px) {
        &--received {
            padding-right: 12px;
        }

        &--sent {
            padding-left: 12px;
            justify-content: flex-end;
        }
    }
}

.replies-area__item-inner {
    width: calc(50% - 20px);

    @media all and (width < 960px) {
        width: 100%;
    }
}
.replies-attached-files {
    margin-top: 0;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 55px;
    padding-right: 20px;
    border-top:1px solid $c-form-item-border;
}
.replies-input-field {
    margin-top: 20px;
    &--attached {
        position: relative;
        padding-left: 35px;
    }
}
