/* .hiddendiv{
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  min-height: 34px;
  padding: 7px 12px 8px;
  font-size: 16px;
  line-height: 19px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 400;
  @media all and (width < 960px){
    font-size: 15px;
  }
}
.lbr {
  line-height: 0;
} */