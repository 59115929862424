.tab {
    cursor: pointer;
    width: 166px;
    min-height: 50px;
    text-align: center;
    font-family: 'roboto-condensed';
    font-weight: 400;
    background-color: $c-btn-primary-bg;
    color: $c-btn-primary-font;
    &:hover {
        background-color: $c-btn-primary-bg-hover;
    }
    & + & {
        margin-left: 1px;
    }
    &.current {
        cursor: default;
        color: $c-tab-font-current;
        background-color: $c-tab-bg-current;
    }
}
