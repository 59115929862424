.icon-svg-wrap {
    display: inline-block;
}
.icon-svg-wrap--btn {
    cursor: pointer;
    color: $c-font-active;
    &:hover {
        color: $c-font-active-hover;
    }
}
.icon-svg-wrap--size-24 {
    width: 24px;
    height: 24px;
}
/**********ICON-TXT*************/
.link-txt {
    .icon-svg-wrap {
        @include size-vertical-center;
        width: 24px;
        height: 24px;
    }
    &--icon-left {
        .icon-svg-wrap {
            left: 0;
        }
    }
    &--icon-right {
        .icon-svg-wrap {
            right: 0;
        }
    }
}
.accordion {
    .link-txt {
        .icon-svg-wrap {
            top: -2px;
            bottom: auto;
        }
    }
}
/***************ICON-ALARM*****************/

/**************MAIN-MENU-ICONS*************/
.main-menu__profile-menu {
    .icon-svg-wrap {
        position: absolute;
        left: 18px;
        top: 7px;
        width: 20px;
        height: 20px;
    }
}
.icon-svg-wrap--header-select {
    @media all and (width >= 960px) {
        display: none;
    }
    @include size-vertical-center;
    left: 8px;
    width: 24px;
    height: 24px;
    color: #fbfdfe;
}
.main-menu__btn--icon {
    .icon-svg-wrap {
        color: $c-main-menu-btn-border;
        width: 32px;
        height: 32px;
    }
    &:hover {
        .icon-svg-wrap {
            color: $c-main-menu-btn-border-hover;
        }
    }
}
.sub-menu-left__item-btn {
    .icon-svg-wrap {
        @include size-vertical-center;
        left: 19px;
        width: 24px;
        height: 24px;
        @media all and (width < 960px) {
            top: 8px;
            bottom: auto;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
        @media all and (width < 640px) {
            top: 0;
            bottom: 0;
        }
    }
}
.sub-menu-btn {
    .icon-svg-wrap {
        @include size-vertical-center;
        left: 12px;
        width: 16px;
        height: 16px;
        @media all and (width < 640px) {
            @include size-center;
            width: 14px;
            height: 14px;
        }
    }
}
/****************BTN-ICON***************/
.btn--icon-txt {
    .icon-svg-wrap {
        position: absolute;
        top: 7px;
        width: 20px;
        height: 20px;
    }
}
.btn--icon-right {
    .icon-svg-wrap {
        right: 16px;
    }
}
.btn--icon-left {
    .icon-svg-wrap {
        left: 16px;
    }
}
.btn--icon {
    .icon-svg-wrap {
        @include size-center;
        width: 20px;
        height: 20px;
    }
}
.btn-alt__icon {
    .icon-svg-wrap {
        @include size-center;
        width: 20px;
        height: 20px;
    }
}
/************TABS-TOP-ICON***********/
.tab-top-icon {
    .icon-svg-wrap {
        @include size-horizontal-center;
        top: 11px;
        width: 24px;
        height: 24px;
    }
}
/*************TABS-NAV***************/
.tab-nav {
    .icon-svg-wrap {
        position: absolute;
        top: 15px;
        left: 8px;
        width: 24px;
        height: 24px;
        color: $c-default;
    }
}
/**************ALERTS**************/
.message {
    .icon-svg-wrap {
        position: absolute;
        top: 15px;
        left: 16px;
        /* IE blurred svg issue */
        margin: -12px; /* .25x desired size */
        height: 52px; /* 2x desired size */
        width: 52px; /* 2x desired size */
        transform: scale(0.5);
        background-repeat: no-repeat;
        background-size: 100%;
    }
    &--success {
        .icon-svg-wrap {
            background-image: svg-load(
                '../../svg/svg-icons/icon-check-in-circle.svg',
                fill=none,
                stroke=#{$c-success}
            );
        }
    }
    &--warning {
        .icon-svg-wrap {
            background-image: svg-load(
                '../../svg/svg-icons/icon-close-in-circle.svg',
                fill=none,
                stroke=#{$c-warning}
            );
        }
    }
    &--alert {
        .icon-svg-wrap {
            background-image: svg-load(
                '../../svg/svg-icons/icon-alert.svg',
                fill=none,
                stroke=#{$c-alert}
            );
        }
    }
}
/*************FORM-ITEM-ICON************/
.form-item-wrap--search {
    .icon-svg-wrap {
        @include size-vertical-center;
        right: 8px;
        width: 16px;
        height: 16px;
    }
}
.form-item-prepend {
    .icon-svg-wrap {
        width: 20px;
        height: 20px;
        color: $c-form-item-border;
    }
}
.date,
.time {
    .icon-svg-wrap {
        @include size-vertical-center;
        right: 12px;
        width: 20px;
        height: 20px;
        color: $c-form-item-border;
    }
    &:not(.disabled):hover {
        .icon-svg-wrap {
            color: $c-form-item-border-focused;
        }
    }
    &.disabled {
        .icon-svg-wrap {
            color: $c-form-item-border;
        }
    }
}
.fileupload__label {
    .icon-svg-wrap {
        @include size-horizontal-center;
        top: 28px;
        width: 24px;
        height: 24px;
    }
}
.fileupload.single {
    .fileupload__file {
        .icon-svg-wrap {
            z-index: 7;
            @include size-horizontal-center;
            top: 28px;
            width: 24px;
            height: 24px;
            color: $c-font-active;
            &:hover {
                color: $c-font-active-hover;
            }
        }
    }
}

/*********************************/
.table {
    .icon-svg-wrap {
        width: 24px;
        height: 24px;
    }
}
/***********TAB-ICON*****************/
.tab-icon {
    .icon-svg-wrap {
        position: absolute;
        @include size-center;
        width: 40px;
        height: 40px;
        color: $c-font-active;
    }
    &:not(.current):not(.disabled):hover {
        .icon-svg-wrap {
            color: $c-font-active-hover;
        }
    }
    &.disabled {
        .icon-svg-wrap {
            color: $c-form-item-border-disabled;
        }
    }
}
/* Dropdown nesting menu */
.dropdown__item--submenu-l1 {
    .icon-svg-wrap {
        @include size-vertical-center;
        width: 24px;
        height: 24px;
        right: 24px;
    }
}
/* Dropdown menu item icon */
.dropdown__item--icon {
    .icon-svg-wrap {
        position: absolute;
        top: 7px;
        left: 16px;
        width: 20px;
        height: 20px;
    }
}
.icon-svg-wrap--replies-input-attached {
    @include size-vertical-center;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    left: 0;
    @media all and (width < 960px){
        top: auto;
        bottom: 6px;
    }
}
.icon-svg-wrap--replies-input-send {
    position: absolute;
    right: 12px;
    bottom: 6px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
}
