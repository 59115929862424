.list-or {
    counter-reset: li;
    list-style: none;
    padding-left: 20px;
    & > li {
        position: relative;
        padding-left: 18px;
        &:before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            width: 20px;
            white-space: nowrap;
            text-align: right;
            left: -20px;
        }
    }
    .list-un {
        margin-top: 8px;
        margin-bottom: 12px;
        margin-left: 3px;
    }
}
.list-un {
    li {
        position: relative;
        padding-left: 25px;
        &:before {
            @include pseudo;
            left: 0;
            top: 11px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $c-default;
        }
    }
    .list-or {
        margin-top: 19px;
        margin-bottom: 19px;
        margin-left: 3px;
    }
}
