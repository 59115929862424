.breadcrumbs {
    @include flex-start-start;
    li {
        position: relative;
        padding-right: 32px;
        &:after {
            @include pseudo;
            @include size-vertical-center;
            right: 13px;
            width: 6px;
            height: 8px;
            border-left: 6px solid $c-border;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
        }
        &:last-child {
            padding-right: 0;
            &:after {
                display: none;
            }
        }
    }
    a {
        display: block;
        font-size: 18px;
        line-height: 21px;
        color: $c-font-active;
        &:not(.current):hover {
            color: $c-font-active-hover;
        }
        &.current {
            color: $c-default;
        }
    }
}
