.form-item-append {
    @include flex-center-center;
    /*float: left;*/
    height: 100%;
    min-width: 40px;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
    border-radius: 0 5px 5px 0;
    background-color: $c-form-item-bg-disabled;

    &--transparent {
        background-color: transparent;
    }

    &--buttons {
        padding-left: 12px;
        padding-right: 12px;
        flex: 0 0 auto;
    }
}

.form-item-append__btn {
    flex: 0 0 auto;
}

.form-item-append__btn + .form-item-append__btn {
    margin-left: 12px;
}
