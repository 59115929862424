.dataTables_wrapper {
    width: 100% !important;
/*     border: 1px solid $c-form-item-border; */
}
.dataTables_wrapper.no-footer .dataTables_scrollBody,
table.dataTable.no-footer,
table.dataTable thead td {
    border-bottom: none;
}
table.dataTable thead th {
    border-bottom-color: $c-form-item-border;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
}
.DTFC_ScrollWrapper {
    box-sizing: content-box;
}
table.dataTable {
    margin: 0;
}
.table.dataTable thead .sorting,
table.dataTable thead .sorting_desc {
    background-image: none;
}
.dataTables_scrollHead {
    background-color: $c-table-th-bg;
}
.dataTables_scrollHeadInner {
}
.dataTables_scrollBody {
    /* scrollbar-width: thin; */
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        /* width: 8px; */
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 0;
    }
}
.hiddenscroll .dataTables_scrollBody {
    &::-webkit-scrollbar {
        display: none;
    }
}
.table.dataTable {
    th,
    td {
        padding: 16px 15px 15px;
        vertical-align: top !important;
        &:first-child {
            padding-left: 20px;
        }
        &:last-child {
            padding-right: 20px;
        }
    }
    td.t-cell-icon-24 {
        padding-top: 13px;
        padding-bottom: 13px;
        &:last-child {
            padding-right: 15px;
        }
    }
    &--editable {
        .t-cell-editable {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}
.table--editable.dataTable,
.table--editable.dataTable {
    .t-cell-editable {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}
.table.dataTable thead th {
    position: relative;
    &.sorting_asc,
    &.sorting_desc,
    &.sorting {
        padding-right: 33px;
        &:before,
        &:after {
            @include pseudo;
            top: 50%;
            right: 12px;
            width: 8px;
            height: 6px;
            border-top: 6px solid $c-table-sort-arrow;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }
        &:before {
            transform: translateY(-4px) rotate(180deg);
        }
        &:after {
            transform: translateY(4px);
        }
        &:hover {
            &:before,
            &:after {
                border-top-color: $c-table-sort-arrow-hover;
            }
        }
    }
    &.sorting_asc {
        &:after {
            display: none;
        }
    }
    &.sorting_desc {
        &:before {
            display: none;
        }
    }
}
td.sorting_1 {
    background-color: $c-table-sorting-bg !important;
}
.dataTables_wrapper .dataTables_filter {
    margin-bottom: 15px;
    color: $c-default;
    font-size: 16px;
    line-height: 19px;
}
.dataTables_wrapper .dataTables_filter input {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    color: $c-default;
}
.dataTables_info {
    display: none;
}
