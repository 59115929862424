/****Header switcher****/
/* This should be refactored.. */
.switch {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.switch-input {
    display: none;
}

.switch-label {
    display: inline-flex;
}

.switch__status {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    color: $c-default;
    flex-shrink: 0;
    &.current {
        cursor: auto;
        color: $c-btn-switch-on;
        .switch__btn {
            display: none;
        }
    }
    &.disabled {
        cursor: auto;
    }
    &--left {
        padding-right: 48px;
        margin-right: -37px;
    }
    &--right {
        padding-left: 48px;
    }
    label {
        cursor: auto;
    }
    &--dark {
        color: #fff;
    }
    &--dark.current {
        color: $c-btn-switch-disabled;
    }
}

/* .switch--multiple {
	.switch__status {
		&--left {
			margin-right: -37px;
		}
	}
} */

.switch__btn {
    position: relative;
    display: block;
    height: 21px;
    width: 37px;
    border: 1px solid $c-btn-switch-off;
    background-color: #fff;
    border-radius: 10px;
    z-index: 10;
    &:not(.disabled) {
        cursor: pointer;
    }
    &::after {
        @include size-vertical-center;
        @include pseudo;
        width: 15px;
        height: 15px;
        border: 1px solid $c-btn-switch-off;
        background-color: #fff;
        border-radius: 50%;
    }
    &--dark {
        border-color: #fff;
    }
}
/* .disabled {
	.switch__btn {
			background-color:$c-btn-switch-disabled;
		&:after {
			border-color:$c-btn-switch-disabled;
		}
	}
} */
.switch__status .switch__btn {
    @include size-vertical-center;
    background-color: $c-btn-switch-on;
    border-color: $c-btn-switch-on;
    &::after {
        border-color: #fff;
    }
    &--dark {
        border-color: #fff;
        background-color: #fff;
        &::after {
            border-color: $c-btn-switch-on;
            background-color: $c-btn-switch-on;
        }
    }
}

.disabled.switch__btn {
    cursor: auto;
    border-color: $c-btn-switch-disabled;
    background-color: $c-btn-switch-disabled;
    &::after {
        border-color: #fff;
    }
    &--dark {
        border-color: #fff;
        background-color: #fff;
        &::after {
            border-color: $c-btn-switch-disabled;
            background-color: $c-btn-switch-disabled;
        }
    }
}
.switch__status--left {
    .switch__btn {
        right: 0;
        &:after {
            right: 2px;
        }
    }
}
.switch__status--right {
    .switch__btn {
        left: 0;
        &:after {
            left: 2px;
        }
    }
}
input[type='checkbox'] {
    & + .switch__btn {
        &::after {
            left: 2px;
            right: auto;
        }
    }
}
input[type='checkbox']:checked {
    & + .switch__btn {
        background-color: $c-btn-switch-on;
        border-color: $c-btn-switch-on;
        &::after {
            right: 2px;
            left: auto;
            border-color: #fff;
        }

        &--dark {
            background-color: #fff;
            border-color: #fff;

            &::after {
                background-color: $c-btn-switch-on;
                border-color: $c-btn-switch-on;
            }
        }
    }
    & + .disabled.switch__btn {
        border-color: $c-btn-switch-disabled;
        background-color: $c-btn-switch-disabled;
        &::after {
            border-color: #fff;
        }
        &--dark {
            border-color: #fff;
            background-color: #fff;
            &::after {
                border-color: $c-btn-switch-disabled;
                background-color: $c-btn-switch-disabled;
            }
        }
    }
}
