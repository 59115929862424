.reply {
    padding: 20px;
    border-radius: 5px;

    @media all and (width < 960px) {
        padding: 12px;
    }
}

.reply__header-item {
    font-family: 'roboto-condensed';
    font-style: italic;
    font-size: 16px;
    line-height: 22px;

    @media all and (width < 960px) {
        font-size: 15px;
        line-height: 20px;
    }
}

.reply__body {
    margin-top: 4px;
    font-family: 'roboto-condensed';
    font-size: 16px;
    line-height: 22px;

    @media all and (width < 960px) {
        font-size: 15px;
        line-height: 20px;
    }
}
.reply__files-wrap {
    display: none;
    margin-top: 5px;
    &.active {
        @include flex-start-start;
        flex-wrap:wrap;
    }
}

.reply--received {
    background-color: $c-bg-disabled;

    .reply__header-item {
        color: $c-border-alt;
    }
    .reply__body {
        color: $c-body-font;
    }
}

.reply--sent {
    background-color: $c-dark-on-light;

    .reply__header-item {
        color: $c-border;
    }
    .reply__body {
        color: $c-body-bg;
    }
}
