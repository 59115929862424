/*----------  Theme UIKit  ----------*/

select.select2 + .select2-container--uikit {
    width: 100%;
}
.select2-container--uikit {
    .select2-selection {
        @include flex-start-center;
        height: 100%;
        padding-left: 12px;
        padding-right: 32px;
        position: relative;
        background-color: transparent;
        border: none;
        border-radius: 5px;

        .select2-selection__rendered {
            padding: 0;
            font-size: 16px;
            line-height: 22px;
            /* color:$c-form-item-placeholder; */
            color: $c-form-item-font;
        }

        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
        }

        .select2-selection__placeholder {
            color: $c-form-item-placeholder;
        }

        .select2-selection__arrow {
            position: absolute;
            width: 32px;
            height: 100%;
            top: 0px;
            right: 0px;
            b {
                height: 5px;
                width: 8px;
                border-color: $c-form-item-border transparent transparent
                    transparent;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                left: 50%;
                margin-left: -4px;
                margin-top: -2px;
                position: absolute;
                top: 50%;
            }
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            background-color: $c-form-item-bg-disabled;
            cursor: auto;
            .select2-selection__clear {
                display: none;
            }
            .select2-selection__rendered {
                color: $c-form-item-font-disabled;
                cursor: auto;
            }
        }
    }

    &.select2-container--open {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent $c-form-item-border
                    transparent;
                border-width: 0 4px 5px 4px;
            }
        }
        &:hover .select2-selection__arrow b {
            border-bottom-color: $c-form-item-border-focused;
        }
    }

    &:not(.select2-container--disabled) {
        .select2-selection:hover {
            .select2-selection__arrow b {
                border-top-color: $c-form-item-border-focused;
            }
        }
    }

    /*----------  Dropdown  ----------*/

    .select2-dropdown {
        box-sizing: content-box;
        border: 1px solid $c-form-item-border;
        border-radius: 5px;
        background: $c-bg;
        z-index: 2500;
        overflow: hidden;
        &.left {
            right: 0 !important;
            left: auto !important;
        }
        &.right {
            left: 0 !important;
            right: auto !important;
        }
    }

    .select2-results__options {
        scrollbar-width: thin;
        scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
        &::-webkit-scrollbar {
            width: 8px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            background-color: $c-scrollbar-track-bg;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $c-scrollbar-thumb-bg;
            border-radius: 4px;
        }
    }

    &.select2-container--open .select2-dropdown--above {
        transform: translateY(-4px) translateX(-1px);
    }

    &.select2-container--open .select2-dropdown--below {
        transform: translateY(4px) translateX(-1px);
    }

    .select2-search--dropdown {
        position: relative;
        padding: 9px 14px 9px;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid $c-form-item-border;
        font-size: 0;
        line-height: 0;
        &::after {
            z-index: 10;
            @include pseudo;
            @include none-size-vertical-center;
            right: 23px;
            width: 16px;
            height: 16px;
            background-image: url(../images/icon_search.svg);
        }

        .select2-search__field {
            height: 36px;
            width: 100%;
            padding: 1px 35px 0 14px;
            border: 1px solid $c-form-item-border;
            border-radius: 5px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: $c-form-item-font;
        }

        &.select2-search--hide {
        }
    }

    .select2-results > .select2-results__options {
        max-height: 200px;
        overflow-y: auto;
        border-top: 8px solid rgba(0, 0, 0, 0);
        border-bottom: 8px solid rgba(0, 0, 0, 0);
    }

    .select2-results__option {
        padding: 8px 12px 9px;
        font-size: 16px;
        line-height: 19px;
        color: $c-form-item-font;
        background-color: transparent;
        &[role='group'] {
            padding: 0;
            margin-top: 18px;
            &:first-child {
                margin-top: 0;
                /* padding-top: 7px; */
            }
        }

        &[aria-disabled='true'] {
            color: $c-form-item-border-disabled;
        }

        &[aria-selected='true'] {
            /* background-color: #ddd; */
        }

        .select2-results__option {
            padding-left: 34px;
            position: relative;
            &:before {
                @include pseudo;
                top: 15px;
                left: 14px;
                width: 7px;
                height: 7px;
                background-color: $c-default;
                border-radius: 50%;
            }
            &:first-child {
                margin-top: 5px;
            }
        }
    }

    .select2-search--hide + .select2-results {
        .select2-results__option {
            &[role='group'] {
                &:first-child {
                    padding-top: 9px;
                }
            }
        }
    }

    .select2-results__option--highlighted[aria-selected],
    .select2-results__option[aria-selected='true'] {
        background-color: $c-form-item-bg-disabled;
    }

    .select2-results__group {
        padding: 0;
        padding-left: 14px;
        padding-right: 14px;
        font-size: 16px;
        line-height: 19px;
        @include roboto-condensed-bold;
        text-transform: none;
        color: $c-form-item-font;
    }
}

/*----------  Select2 in header  ----------*/
.form-item-wrap--header-select {
    .select2-container--uikit {
        .select2-selection--single {
            .select2-selection__rendered {
                color: $c-header-styler-font;
            }

            .select2-selection__placeholder {
                color: $c-form-item-placeholder;
            }

            .select2-selection__arrow {
                width: 24px;
                border-left: 1px solid $c-main-menu-btn-border;
                b {
                    border-color: $c-header-select-arrow transparent transparent
                        transparent;
                    border-style: solid;
                    border-width: 6px 4px 0 4px;
                    height: 0;
                    width: 0;
                }
            }
        }
        &.select2-container--disabled {
            .select2-selection--single {
                background-color: transparent;
                .select2-selection__rendered {
                    color: $c-header-styler-font-disabled;
                }
            }
        }

        &:not(.select2-container--disabled) {
            .select2-selection--single:hover {
                .select2-selection__arrow {
                    border-left-color: $c-main-menu-btn-border-hover;
                }
                .select2-selection__arrow b {
                    border-top-color: $c-main-menu-btn-border-hover;
                }
            }
        }

        &.select2-container--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    b {
                        border-color: transparent transparent
                            $c-header-select-arrow transparent;
                        border-width: 0 4px 5px 4px;
                    }
                }
                &:hover {
                    .select2-selection__arrow {
                        border-left-color: $c-main-menu-btn-border-hover;
                    }
                    .select2-selection__arrow b {
                        border-bottom-color: $c-main-menu-btn-border-hover;
                    }
                }
            }
        }

        @media all and (width < 960px) {
            .select2-selection {
                padding: 0;
                padding-left: 32px;
                &:before {
                    @include pseudo;
                    width: 24px;
                    height: 24px;
                    left: 8px;
                    @include size-vertical-center;
                    background-image: svg-load(
                        '../../svg/svg-icons/icon-users.svg',
                        fill=none,
                        stroke=#{$c-main-menu-btn-font}
                    );
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                }
            }
            .select2-selection__rendered {
                display: none;
            }
            .select2-selection--single .select2-selection__arrow {
                border-left: none;
            }
        }
    }
}

/*----------  Select2 in header Dropdown  ----------*/
.select2-container--uikit {
    .header-select-dropdown {
        width: 285px !important;
        width: 100%;
        border: none;
        background: $c-header-dropdown-bg;
        box-shadow: 0 1px 2px 0 $c-header-dropdown-shadow;
        @media all and (width < 960px) {
            width: 200px !important;
        }

        .select2-search--dropdown {
            padding: 12px 12px 8px;
            border-bottom: none;
        }

        .select2-results > .select2-results__options {
            max-height: 316px;
            overflow-y: auto;
        }
    }
}

/* Select2 Multiple */

.select2-container--uikit {
    .select2-dropdown--multiple {
        min-width: 280px;
        max-width: 400px;
        padding-top: 5px;

        .select2-all {
            cursor: pointer;
            position: relative;
            display: inline-flex;
            padding: 0;
            font-size: 16px;
            line-height: 19px;
            @include roboto-condensed-regular;
            text-decoration: none;
            color: $c-font-active;
            background-color: transparent;
            &:hover {
                color: $c-font-active-hover;
            }
            &:first-child {
                margin-left: 12px;
                margin-right: 20px;
            }
        }

        .select2-results__option {
            position: relative;
            display: block;
            width: 100%;
            padding: 7px 10px 10px 47px;

            &:first-child {
                margin-top: 8px;
            }
        }

        .select2-results__option:before,
        .select2-results__option:after {
            @include pseudo;
        }
        .select2-results__option:before {
            top: 8px;
            left: 15px;
            width: 20px;
            height: 20px;
            border-width: 1px;
            border-style: solid;
            border-radius: 5px;
            border-color: $c-form-item-border;
            background-color: $c-bg;
        }
        .select2-results__option--highlighted[aria-selected] {
            &:before {
                border-color: $c-form-item-border-focused;
            }
        }
        .select2-results__option:after {
            opacity: 0;
            @include pseudo;
            top: 14px;
            left: 19px;
            width: 12px;
            height: 6px;
            border-left: 1px solid $c-form-item-border-focused;
            border-bottom: 1px solid $c-form-item-border-focused;
            transform: rotate(-45deg);
        }
        .select2-results__option[aria-selected='true'] {
            background-color: transparent;
            &:after {
                opacity: 1;
                display: block;
            }
            /* &:hover {
                background-color: $c-form-item-bg-disabled;
            } */
        }
        .select2-results__option[role='group'] {
            margin-top: 12px;
            padding: 0 !important;
            cursor: auto;

            &:first-child {
                margin-top: 0;
                /* margin-top: 8px; */
            }

            &:before,
            &:after {
                display: none;
            }
        }
        .select2-search--dropdown {
            margin-top: 5px;
            padding-top: 0;
            padding-left: 11px;
            padding-right: 11px;
            padding-bottom: 8px;
            border: none;

            &::after {
                top: 10px;
                transform: none;
            }
        }
    }
}

/*----------  Datepicker styles  ----------*/

.ui-datepicker-title {
    .select2-container--uikit {
        border: 1px solid $c-form-item-border;
        border-radius: 5px;
        .select2-selection--single {
            .select2-selection__rendered {
                font-size: 16px;
                @include roboto-condensed-regular;
                color: $c-form-item-font;
            }
            .select2-selection__arrow {
            }
        }
        &.select2-container--focus {
            border-color: $c-form-item-border-focused;
        }
    }
}
.select2-container--uikit {
    .select2-dropdown--datepicker {
        box-sizing: border-box;
    }
    &.select2-container--open
        .select2-dropdown--datepicker.select2-dropdown--above {
        transform: translateY(-4px);
    }

    &.select2-container--open
        .select2-dropdown--datepicker.select2-dropdown--below {
        transform: translateY(4px);
    }
}
/* ------ End of Datepicker styles ------ */

.form-item-wrap--period-year {
    .select2-container--uikit .select2-selection {
        padding-right: 28px;
    }
}
