.form {
    @media all and (width < 960px) {
        display: block;
    }
    tr {
        @media all and (width < 960px) {
            display: block;
        }
    }
    td {
        padding: 0;
        vertical-align: top;
        @media all and (width < 960px) {
            display: block;
            padding-top: 14px;
            &:first-child {
                padding-top: 0;
            }
        }
        &:first-child {
            text-align: right;
            @media all and (width < 960px) {
                text-align: left;
            }
        }
    }
    td + td {
        padding-left: 12px;
        @media all and (width < 960px) {
            padding-left: 0;
        }
    }
    tr + tr {
        td {
            padding-top: 12px;
            @media all and (width < 960px) {
                padding-top: 8px;
                &:first-child {
                    padding-top: 14px;
                }
            }
        }
    }
}
.form td:first-child {
    .form-label {
        margin-right: 0;
        text-align: right;
        @media all and (width < 960px) {
            text-align: left;
        }
    }
}
