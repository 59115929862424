.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-color: transparent;
    &--single {
        background-position: center center;
        background-size: contain;
    }
    &--sprite {
        background-position: 0 0;
        background-size: cover;
    }
}
.icon {
    background-color: transparent;
}
