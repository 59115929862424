.tab-top-icon {
    cursor: pointer;
    position: relative;
    width: 164px;
    min-height: 67px;
    padding-top: 40px;
    padding-bottom: 11px;
    border: 1px solid $c-border-alt;
    font-size: 14px;
    line-height: 16px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'roboto-condensed';
    font-weight: 400;
    background-color: $c-bg-disabled;
    color: $c-font-active;
    &:not(.current):not(:disabled):hover {
        color: $c-font-active-hover;
    }
    &.current {
        cursor: default;
        color: $c-default;
        background-color: $c-bg;
    }
    &:disabled {
        color: $c-disabled;
        cursor: default;
    }
}
.tab-group {
    .tab-top-icon {
        &:first-child {
            border-radius: 5px 0 0 5px;
        }
        &:not(:first-child) {
            border-left: 1px solid $c-border-alt;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &:not(:last-child) {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
