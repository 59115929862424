.cross {
    position: absolute;
    width: 19px;
    height: 19px;
    transform: rotate(45deg);
    background-color: transparent;
    cursor: pointer;
}
.cross:before {
    width: 100%;
    height: 1px;
}
.cross:after {
    width: 1px;
    height: 100%;
}
.cross:before,
.cross:after {
    @include pseudo;
    @include size-center;
    background-color: $c-font-active;
    transition: background-color 0.25s;
}
.cross:hover:before,
.cross:hover:after {
    background-color: $c-font-active-hover;
}
.cross--added-element {
    top: 0;
    bottom: 0;
    right: 14px;
    margin-top: auto;
    margin-bottom: auto;
    @media all and (width < 640px) {
        right: 10px;
    }
}

.cross--size-16 {
    width: 23px;
    height: 23px;
}
