.table-wrap {
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    border:1px solid $c-form-item-border;
}
.table {
    font-size: 16px;
    line-height: 19px;
    /* box-sizing: content-box; */
    table-layout: auto;

    &:not(.dataTable) {
        /* table-layout: fixed; */
        width: 100% !important;
        border: none;
/*         border: 1px solid $c-form-item-border; */
        background-color: $c-form-item-bg-disabled;
    }

    th,
    td {
        padding: 16px 15px 15px;
        border: 0;
        box-sizing: content-box;
        vertical-align: top;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }

    td.t-cell-icon-24 {
        font-size: 0;
        line-height: 0;
        padding-top: 13px;
        padding-bottom: 13px;
        &:last-child {
            padding-right: 15px;
        }
    }

    &--editable {
        .t-cell-editable {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    th {
        background-color: $c-table-th-bg;
        border-bottom: 1px solid $c-form-item-border;
        border-right: 1px solid $c-form-item-border-disabled;
        font-weight: 400;
        text-align: left;
        color: $c-default;
        outline: none;

        &:not(:only-child):last-child {
            border-right: 0;
        }
    }

    td {
        position: relative;
        background-color: $c-table-td-bg;
        border-right: 1px solid $c-form-item-border-disabled;
        background-clip: padding-box;

        &:not(:only-child):last-child {
            border-right: 0;
        }

        &::after {
            @include pseudo;
            top: 1px;
            left: 0;
            width: 100%;
            height: 1px;
            @include rlg;
        }
    }

    tr {
        &:first-child {
            td {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.table.w-table-collapse {
    .level-1-row {
        td:first-child {
            padding-left: 40px;
        }

        &:not(.disabled) {
            cursor: pointer;

            td:first-child::before {
                @include pseudo;
                left: 22px;
                top: 50%;
                margin-top: -4px;
                width: 9px;
                height: 9px;
                border-left: 1px solid $c-accord-arrow;
                border-top: 1px solid $c-accord-arrow;
                transform: rotate(135deg);
            }

            &.active {
                td:first-child::before {
                    transform: rotate(-135deg);
                    margin-top: -6px;
                }
            }
        }
    }

    .level-2-row {
        display: none;

        td:first-child {
            padding-left: 52px;
        }

        td {
            padding-top: 4px;
            padding-bottom: 5px;

            &::after {
                display: none;
            }
        }

        &.active {
            display: table-row;
        }
    }

    .level-1-row + .level-2-row {
        td {
            padding-top: 0;
        }
    }

    .level-2-row-last,
    .level-2-row:last-child {
        td {
            padding-bottom: 15px;
        }
    }
}

.t-cell-inner-wrap {
    display: block;
}

.table--busy {
    line-height: 22px;

    th,
    td {
        border-right: 0;
    }
    th {
        padding: 6px 10px;
    }

    td {
        vertical-align: top;
        padding: 20px 10px;

        &::after {
            @include rlg-disabled;
        }
    }

    .t-cell-icon-24 {
        padding-top: 19px;
        padding-bottom: 19px;
    }

    &.table--editable {
        td {
            padding-top: 26px;
        }
        td.t-cell-editable {
            padding-top: 20px;
        }
    }
}

.table--responsive {
    @media all and (width < 960px) {
        display: block;
        font-size: 15px;
        line-height: 20px;
        thead,
        th {
            display: none;
        }
        tbody {
            display: block;
        }
        tr {
            display: block;
            width: 100%;
        }
        td {
            @include flex-start-stretch;
            width: 100%;
            padding: 0 !important;
            border: 0;

            &::before {
                width: 100px;
                flex: 0 0 auto;
                content: attr(data-th);
                border-left: 1px solid $c-form-item-border;
                border-right: 2px solid $c-form-item-border;
                text-align: left;
                background-color: $c-table-th-bg;
            }

            .t-cell-inner-wrap {
                flex: 1 1 auto;
            }

            &::before,
            .t-cell-inner-wrap {
                padding: 12px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &::after {
                top: auto;
                bottom: 1px;
            }

            &:last-child {
                &::after {
                    background: $c-form-item-border;
                }
            }
        }
        tr {
            &:first-child {
                td {
                    &::after {
                        display: block;
                    }
                }
                td:first-child {
                    &::before {
                        border-top: 1px solid $c-form-item-border;
                    }
                }
            }
            &:last-child {
                td:last-child {
                    &::after {
                        display: none;
                    }
                }
                td:last-child {
                    &::before {
                        border-bottom: 1px solid $c-form-item-border;
                    }
                }
            }
        }
    }
}

.table--busy.table--responsive {
    @media all and (width < 960px) {
        td {
            &:last-child {
                &::after {
                    background: $c-form-item-border-disabled;
                }
            }
        }
    }
}

.table--editable.table--responsive {
    @media all and (width < 960px) {
        td {
            .form-item-wrap:only-child {
                width: 100% !important;
            }
        }
    }
}
.table--m-lvl {
    th {
        border-right: none;
    }
}
.m-lvl-1-row,
.m-lvl-2-row,
.m-lvl-3-row,
.m-lvl-4-row,
.m-lvl-5-row {
    &:first-child {
        td {
            border-top: none;
        }
    } 
    td {
        font-weight: 600;
        border-top:1px solid #9DBCDA;
        border-right:none;
        &:after {
            display: none;
        }
    }
    & + .m-lvl-data-row {
        td {
            border-top:1px solid #9DBCDA;
            &:after {
                display: none;
            }
        }
    }
}
.m-lvl-1-row {
    td {
        background-color: $c-table-lvl-1-row-bg; 
    } 
}
.m-lvl-2-row {
    td {
        background-color: $c-table-lvl-2-row-bg;
    }
}
.m-lvl-3-row {
    td {
        background-color: $c-table-lvl-3-row-bg;
    }
}
.m-lvl-4-row {
    td {
        background-color: $c-table-lvl-4-row-bg;
    }
}
.m-lvl-5-row {
    td {
        background-color: $c-table-lvl-5-row-bg;
    }
}
.m-lvl-data-row {
    td {
        background-color: $c-table-lvl-data-row-bg;
    }
}
.m-lvl-total-row {
    td {
        font-weight: 600;
        background-color: $c-table-lvl-total-row-bg;
        color:$c-table-lvl-total-font;
        &:first-child {
            text-transform: uppercase;
        }
    }
}