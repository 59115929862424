.ta-r {
    text-align: right;
}
.ta-l {
    text-align: left;
}
.ta-c {
    text-align: center;
}
.ta-c-less-960 {
    @media all and (width < 960px){
        text-align: center;
    }
}
.ta-c-less-640 {
    @media all and (width < 640px){
        text-align: center;
    }
}
.ta-l-less-960 {
    @media all and (width < 960px){
        text-align: left;
    }
}
.ta-l-less-640 {
    @media all and (width < 640px){
        text-align: left;
    }
}
.ta-r-less-960 {
    @media all and (width < 960px){
        text-align: right;
    }
}
.ta-l-less-640 {
    @media all and (width < 640px){
        text-align: right;
    }
}
.c-success {
    color: $c-success !important;
}
.c-alert {
    color: $c-alert !important;
}
.c-warning {
    color: $c-warning !important;
}
.c-disabled {
    color: $c-disabled !important;
}
.no-border {
    border: none !important;
}
