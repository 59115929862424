.side-menu {
    min-height: 100%;
    width: 244px;
    border: 1px solid $c-border;
    border-radius: 5px 0 0 5px;
    background-color: $c-light;
}
.main-field {
    .side-menu {
        border-left: none;
        border-top: none;
        border-bottom: none;
    }
}
.side-menu__header {
    padding: 12px 15px;
    border-bottom: 1px solid $c-border;
}
.side-menu__tab-group {
    padding: 15px 0 15px 15px;
}
.side-menu__tab {
    position: relative;
    padding: 13px 14px 12px 14px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    border-radius: 5px 0 0 5px;
    border-right: none;
    text-align: left;
    font-size: 18px;
    line-height: 21px;
    font-family: 'roboto-condensed';
    color: $c-default;
    &:not(.current):hover {
        background-color: $c-bg-disabled;
    }
    &:not(.current) {
        cursor: pointer;
    }
    &.current {
        border-color: $c-border;
        background-color: $c-bg;
        &:after {
            @include pseudo;
            right: -1px;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: $c-bg;
        }
    }
}
