.dropdown:not(.jq-selectbox):not(.multiselect-alt__menu):not(.timepicker) {
    z-index: 100;
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    margin: 0;
    max-height: 232px;
    overflow-y: auto;
    min-width: 100%;
    max-width: 280px;
    /* padding: 8px 0; */
    border-top: 8px solid rgba(0, 0, 0, 0);
    border-bottom: 8px solid rgba(0, 0, 0, 0);
    border-radius: 5px;
    background-color: $c-dropdown-bg;
    box-shadow: 0 1px 2px 0 $c-dropdown-shadow;
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        width: 8px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 4px;
    }
}
.dropdown {
    &.active {
        display: block !important;
    }
    &.under {
        top: calc(100% + 2px);
    }
    &.above {
        top: auto;
        bottom: calc(100% + 2px);
    }
    &.left {
        right: 0 !important;
        left: auto !important;
    }
    &.right {
        left: 0 !important;
        right: auto !important;
    }
}
.btn-group {
    .dropdown {
        &.under {
            left: 0;
        }
        &.above {
            right: 0;
        }
    }
}
.dropdown__item {
    position: relative;
    @include flex-between-center;
    width: 100%;
    padding: 8px 12px 9px;
    font-size: 16px;
    line-height: 19px;
    color: $c-form-item-font;
    background-color: transparent;
    &:not(.disabled) {
        cursor: pointer;
    }
    &.current {
        cursor: auto;
        color: $c-default;
        background-color: $c-form-item-bg-disabled;
    }
    &--icon {
        padding-left: 50px;
    }
    &--link {
        color: $c-default;
    }

    @media all and (width >= 1280px) {
        &--visible-under-1280 {
            display: none;
        }
    }

    @media all and (width >= 960px) {
        &--visible-under-960 {
            display: none;
        }
    }
}

.no-touchevents {
    .dropdown__item {
        &:not(.disabled):hover {
            background-color: $c-form-item-bg-disabled;
        }
        &--link {
            &:not(.current):hover {
                color: $c-font-active;
                background-color: transparent !important;
            }
        }
    }
}

/************MODIFICATORS*************/
.form-item-wrap--header-select {
    .dropdown:not(.jq-selectbox) {
        width: 200px;
        @media all and (width >= 960px) {
            display: none;
        }
    }
}
.dropdown.timepicker {
    z-index: 100;
}
/* Dropdown nesting menu */
.dropdown--menu,
.dropdown__submenu {
    overflow-y: visible !important;
    max-height: none !important;
}
.dropdown__submenu.active {
    display: block;
}
/* .dropdown__item-wrap:hover > .dropdown__submenu {
	display: block !important;
} */
.dropdown__item--drop {
    padding-right: 12px;
    &::after {
        @include pseudo;
        @include size-vertical-center;
        right: 12px;
        height: 10px;
        width: 6px;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 6px solid $c-btn-alt-drop-arrow;
        transition: transform 0.25s;
    }
    &.active::after {
        transform: rotate(180deg);
    }
}
.dropdown__item--submenu-l1 {
    display: block;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 60px;
    color: $c-btn-alt-font;
}
.dropdown__submenu {
    .dropdown__item {
        padding-left: 16px;
        padding-right: 16px;
    }
}
.dropdown--with-icons {
    padding: 11px 0 !important;
}
.dropdown__item--icon {
    position: relative;
    padding-left: 50px;
}
