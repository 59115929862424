.ui-kit-menu-wrap {
    position: relative;
    width: 280px;
    max-width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    @media all and (width <=639px) {
        width: 320px;
        height: calc(100vh - 40px);
    }
}
@media all and (width > 639px) {
    .med .ui-kit-menu-wrap.ovv,
    .min .ui-kit-menu-wrap.ovv {
        overflow: visible;
    }
    .med .ui-kit-menu-wrap {
        width: 134px;
    }
    .min .ui-kit-menu-wrap {
        width: 60px;
    }
}
