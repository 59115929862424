.pagination {
    display: inline-block;
    position: relative;
    &--arrows {
        padding-left: 33px;
        padding-right: 33px;
    }
}
.pagination {
    .prev,
    .next {
        @include size-vertical-center;
        width: 13px;
        height: 13px;
        border-left: 1px solid $c-font-active;
        border-top: 1px solid $c-font-active;
        &:not(.disabled):hover {
            border-left-color: $c-font-active-hover;
            border-top-color: $c-font-active-hover;
        }
    }
    .prev {
        left: 3px;
        transform: rotate(-45deg);
    }
    .next {
        right: 3px;
        transform: rotate(135deg);
    }
    .disabled {
        border-top-color: $c-form-item-border-disabled;
        border-left-color: $c-form-item-border-disabled;
    }
}
.pagination {
    & > div:not(.prev):not(.next) {
        display: flex;
    }
}
.pagination div {
    a {
        display: inline-block;
        min-width: 32px;
        padding: 5px 10px 6px;
        border-radius: 16px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: $c-font-active;
        background-color: transparent;
        &:not(.current):hover {
            color: $c-font-active-hover;
        }
        &.current {
            background-color: $c-btn-primary-bg;
            color: $c-bg;
        }
    }
    a + a {
        margin-left: 8px;
    }
}
