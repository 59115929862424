/*видимо стили для варианта сингл селекта*/
/*.ui-multiselect-single .ui-multiselect-checkboxes input {
	left:-9999px;
	position:absolute !important;
	top: auto !important;
}*/

@media print {
    .ui-multiselect-menu {
        display: none;
    }
}

.ui-multiselect {
    @include flex-start-center;
    cursor: pointer;
    position: relative;
    margin: 0;
    width: 100% !important;
    height: 100%;
    padding-left: 14px;
    padding-right: 38px;
    font-size: 16px;
    line-height: 22px;
    border: none;
    border-radius: 5px;
    text-align: left;
    color: $c-form-item-font;
    background-color: transparent;
}
.ui-multiselect.ui-state-disabled {
    opacity: 1;
    background-color: $c-form-item-bg-disabled;
}
.ui-icon-triangle-2-n-s,
.ui-icon-triangle-1-s {
    @include size-center;
    left: auto;
    right: 14px;
    width: 8px;
    height: 5px;
    border-color: $c-form-item-border transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
}
.ui-multiselect:not(.ui-state-disabled):hover {
    .ui-icon-triangle-2-n-s,
    .ui-icon-triangle-1-s {
        border-top-color: $c-form-item-border-focused;
    }
}
.ui-state-active {
    .ui-icon-triangle-2-n-s,
    .ui-icon-triangle-1-s {
        transform: rotate(180deg);
    }
}
.ui-icon {
    background-image: none !important;
}
.ui-multiselect-menu {
    z-index: 200;
    position: absolute;
    display: none;
    margin-left: -1px;
    transform: translateY(3px);
    height: auto !important;
    min-width: 280px;
    max-width: 400px;
    width: auto !important;
    border: 1px solid $c-form-item-border !important;
    padding: 10px 0;
    border-radius: 5px;
    text-align: left;
    background-color: $c-bg;
}
.ui-multiselect-header {
    position: static;
    margin-bottom: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    .ui-helper-reset {
        position: static;
        @include flex-start-center;
        width: 100%;
        padding: 0 12px;
        text-align: left;
        .ui-icon {
            display: none;
        }
        li {
            padding: 0;
        }
        li + li {
            margin-left: 20px;
        }
        a {
            cursor: pointer;
            position: relative;
            display: block;
            font-size: 16px;
            line-height: 19px;
            @include roboto-condensed-regular;
            text-decoration: none;
            color: $c-font-active;
            &:hover {
                color: $c-font-active-hover;
            }
        }
    }
    .ui-multiselect-filter + .ui-helper-reset {
        margin-top: 13px;
    }
}
.ui-multiselect-close {
    display: none;
}
.ui-multiselect-filter {
    /*	position: absolute;
	top: 0;
	left: 0;*/
    position: relative;
    width: 100%;
    padding: 0 11px;
    border-radius: 5px 5px 0 0;
    font-size: 0;
    line-height: 0;
    &:after {
        z-index: 10;
        @include pseudo;
        @include size-vertical-center;
        right: 23px;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../images/icon_search.svg);
        background-size: 16px 16px;
    }
}
.ui-multiselect-filter input {
    height: 36px;
    width: 100%;
    padding: 1px 35px 0 14px;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    @include roboto-condensed-regular;
    color: $c-form-item-font;
}
.ui-multiselect-checkboxes {
    overflow-y: auto;
    margin-top: 12px;
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        width: 8px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 4px;
    }
}
/*.ui-multiselect-hasfilter + .ui-multiselect-checkboxes {
	margin-top: 60px;
}*/
.ui-multiselect-checkboxes li {
    clear: both;
    list-style: none;
    padding-right: 0;
    &:first-of-type {
        /*margin-top: 7px;*/
    }
}
.ui-multiselect-optgroup-label + li {
    margin-top: 8px;
}
.ui-multiselect-optgroup-label {
    font-size: 16px;
    line-height: 19px;
    @include roboto-condensed-bold;
    color: $c-form-item-font;
    &:not(:first-of-type) {
        margin-top: 12px;
    }
    a {
        padding-left: 14px;
        padding-right: 14px;
        color: $c-form-item-font;
    }
}
.ui-multiselect-checkboxes li label {
    display: block;
    padding: 0;
    border: none !important;
    background-color: transparent !important;
}
.ui-multiselect-checkboxes li label span {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    padding: 7px 10px 10px 47px;
    font-size: 16px;
    line-height: 19px;
    color: $c-form-item-font;
    background-color: transparent;
    &:hover {
        background-color: $c-form-item-bg-disabled;
    }
}
.ui-multiselect-checkboxes li label span:before,
.ui-multiselect-checkboxes li label span:after {
    @include pseudo;
}
.ui-multiselect-checkboxes li label span:before {
    top: 8px;
    left: 15px;
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: $c-form-item-border;
    background-color: $c-bg;
}
.ui-multiselect-checkboxes li label span:hover {
    &:before {
        border-color: $c-form-item-border-focused;
    }
}
.ui-multiselect-checkboxes li label span:after {
    opacity: 0;
    @include pseudo;
    top: 14px;
    left: 19px;
    width: 12px;
    height: 6px;
    border-left: 1px solid $c-form-item-border-focused;
    border-bottom: 1px solid $c-form-item-border-focused;
    transform: rotate(-45deg);
}
.ui-multiselect-checkboxes li label input:checked + span:after {
    opacity: 1;
    display: block;
}
