.multiselect-alt {
    position: relative;
    width: 285px;
    & > .form-item-wrap {
        position: relative;
        &:after {
            @include pseudo;
            @include size-vertical-center;
            right: 14px;
            width: 10px;
            height: 6px;
            border-top-width: 6px;
            border-top-style: solid;
            border-top-color: $c-form-item-border;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
        &.active {
            &:after {
                transform: rotate(180deg);
            }
        }
        &:hover {
            &:after {
                border-top-color: $c-form-item-border-focused;
            }
        }
        .form-item {
            cursor: pointer;
        }
    }
}
.multiselect-alt__menu {
    display: none;
    position: absolute;
    width: 100%;
    padding: 10px 10px 15px;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    background-color: $c-bg;
    &.active {
        display: block;
    }
    & > .form-item-wrap {
        &:after {
            z-index: 10;
            @include pseudo;
            @include size-vertical-center;
            right: 11px;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../images/icon_search.svg);
            background-size: 16px 16px;
        }
        .form-item {
            padding-right: 38px;
            font-size: 16px;
            line-height: 19px;
        }
    }
}
.multiselect-alt__helper {
    @include flex-start-start;
    margin-top: 10px;
    button {
        padding: 0;
        font-size: 16px;
        line-height: 19px;
        color: $c-font-active;
        background-color: transparent;
        &:hover {
            color: $c-font-active-hover;
        }
    }
    button + button {
        margin-left: 15px;
    }
}
.multiselect-alt__options {
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
    overflow-y: auto;
    max-height: 220px;
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        width: 8px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 4px;
    }
    & > .accordion {
        padding-right: 10px;
    }
    & > .accordion + .accordion {
        margin-top: 18px;
    }
    .checkbox__txt {
        padding-left: 30px;
    }
    & > .accordion > .accordion__head {
        .checkbox__txt {
            font-weight: 600;
        }
    }
    .accordion__body > .checkbox {
        margin-left: 33px;
    }
    & > .accordion > .accordion__body {
        padding-bottom: 0;
    }
    .accordion__body > .checkbox + .checkbox {
        margin-top: 12px;
    }
    & > .type-4 + .type-4 {
        padding-top: 17px;
        &:before {
            @include pseudo;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: repeating-linear-gradient(
                to right,
                $c-form-item-border-disabled 0px,
                $c-form-item-border-disabled 6px,
                transparent 6px,
                transparent 10px
            );
        }
    }
}
