.ft_container table {
    margin: 0px;
    border-collapse: collapse;
    margin: 0;
}

.ft_container table tr th {
}

.ft_container table thead {
    -moz-user-select: none;
    -webkit-user-select: none;
}
.ft_container table tr th,
.ft_container table tr td {
    border-collapse: collapse;
    word-wrap: break-word;
    overflow: hidden;
    word-wrap: break-word;
}

.ft_container table tr:first-child td,
.ft_container table tr:first-child th {
}
.ft_container table tr td:first-child,
.ft_container table tr th:first-child {
}
.ft_container {
    overflow: hidden;
    padding: 0px;
}
.ft_rel_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.ft_r,
.ft_rc {
    background-image: none;
}
.ft_rc {
    position: absolute;
    z-index: 1005;
}
.ft_r,
.ft_c {
    position: relative;
}
.ft_rwrapper,
.ft_cwrapper {
    overflow: hidden;
    position: absolute;
    z-index: 1001;
    border-width: 0px;
    padding: 0px;
    margin: 0px;
}
.ft_scroller {
    overflow: auto;
    height: 100%;
    padding: 0px;
    margin: 0px;
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        width: 17px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 0;
    }
}
.ft_container tbody tr.ui-widget-content,
thead.ui-widget-header {
    background-image: none;
}
.ft_container table.sorttable thead tr th {
    cursor: pointer;
}
.ft_container table thead tr th.fx_sort_bg {
}
.ft_container table thead tr th.fx_sort_asc {
}
.ft_container table thead tr th.fx_sort_desc {
}
