.main-menu {
    @include flex-start-center;
    height: 60px;
    padding-left: 18px;
    padding-right: 20px;
    background-color: $c-main-menu-bg;
    border-bottom: 1px solid $c-submenu-bg;
}
.main-menu > * {
    flex: 0 0 auto;
}
.main-menu__logo {
    width: 160px;
    height: 34px;
    @media all and (width < 640px) {
        width: 113px;
        height: 24px;
    }
}
.main-menu__right-part {
    @include flex-start-center;
    margin-left: auto;
}
.main-menu__profile {
    position: relative;
    height: 32px;
    font-style: 0;
    line-height: 0;

    @media all and (width >= 960px) {
        margin-left: 30px;
    }
}
.main-menu__profile-btn {
    background-color: transparent;
    position: relative;
    width: 32px;
    height: 32px;
    padding-left: 0;
    padding-right: 0;
    font-size: 0;
    line-height: 0;

    &:before {
        @include pseudo;
        @include size-center;
        width: 100%;
        height: 20px;
        border-top: 1px solid $c-main-menu-profile-btn;
        border-bottom: 1px solid $c-main-menu-profile-btn;
    }
    &:after {
        @include pseudo;
        @include size-center;
        width: 100%;
        height: 1px;
        background-color: $c-main-menu-profile-btn;
    }

    &:hover {
        &:before {
            border-color: $c-main-menu-profile-btn-hover;
        }
        &:after {
            background-color: $c-main-menu-profile-btn-hover;
        }
    }
}
.main-menu__profile-menu {
    width: 224px;
    max-height: none !important;
    padding-bottom: 11px !important;
    .dropdown__item {
        padding-right: 16px;
        justify-content: space-between;

        &.alert {
            color: $c-alert !important;
        }

        .indicator {
            margin-left: 0;
        }
    }
}
.main-menu__profile-name {
    display: block;
    padding: 5px 18px 10px;
    font-size: 16px;
    line-height: 19px;
    font-family: 'roboto-condensed', sans-serif;
    font-weight: 700;
    color: $c-default;
}
.main-menu__profile-name + .main-menu__profile-menu-item {
    margin-top: 12px;
}
.main-menu__profile-menu-item {
    position: relative;
    display: block;
    padding-left: 35px;
    background-color: transparent;
    color: $c-main-menu-profile-font;
    &:hover {
        color: $c-main-menu-profile-font-hover;
    }
    & + & {
        margin-top: 9px;
    }
}
.main-menu__btn {
    cursor: pointer;
    display: inline-block;
    padding: 5px 12px;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid $c-btn-primary-border;
    border-radius: 5px;
    color: $c-main-menu-btn-font;
    background-color: $c-btn-primary-bg;
    &:hover {
        background-color: $c-main-menu-btn-bg-hover;
        border-color: $c-main-menu-btn-bg-hover;
    }
    &--outline {
        border-color: $c-main-menu-btn-border;
        background-color: transparent;
        &:hover {
            border-color: $c-main-menu-btn-border-hover;
            background-color: transparent;
        }
    }
    &--icon {
        width: 32px;
        height: 32px;
        padding: 0;
        font-style: 0;
        line-height: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
    }
    &--text {
        color: $c-main-menu-btn-border;
        border: 0;
        background-color: transparent;

        &:hover {
            color: $c-main-menu-btn-border-hover;
            background-color: transparent;
        }
    }
    &--next-to-logo {
        position: relative;
        margin-left: 40px;
        .icon-svg-wrap {
            @include size-center;
            color: $c-main-menu-dashboard-mobile;
        }
        @media all and (width >= 640px) {
            .icon-svg-wrap {
                display: none;
            }
        }

        @media all and (width < 640px) {
            position: relative;
            margin-left: 15px;
            width: 32px;
            height: 32px;
            padding-left: 0;
            padding-right: 0;
            font-size: 0;
            line-height: 0;
        }
    }
    &--next-to-select {
        margin-left: 12px;
        @media all and (width < 640px) {
            display: none;
        }
    }
    &--hidden-under-1280 {
        @media all and (width < 1280px) {
            display: none;
        }
    }
    &--hidden-under-960 {
        @media all and (width < 960px) {
            display: none;
        }
    }
}
.main-menu__right-part {
    .main-menu__btn--text {
        margin-right: 28px;
    }
    .main-menu__btn--icon + .main-menu__btn--icon {
        margin-left: 30px;
    }
}
.main-menu__list-btn-mobile {
    @media all and (width >= 960px) {
        display: none;
    }
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: $c-main-menu-bg;
    &:after {
        @include pseudo;
        top: 13px;
        right: 7px;
        width: 8px;
        height: 6px;
        border-top: 6px solid $c-font-active;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
    }
    &.active {
        &:after {
            transform: rotate(180deg);
        }
    }
    &:hover {
        &:after {
            border-top-color: $c-form-item-border-disabled;
        }
    }
}
.main-menu__btn--badge,
.main-menu__profile-btn--badge {
    position: relative;

    .indicator {
        position: absolute;
        right: 13px;
        top: 13px;
        transform: translate(100%, -100%);
        border: 4px solid $c-main-menu-bg;
    }
}
.main-menu__profile-btn--badge {
    margin-right: 4px;
}
