.mdl-wrap {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    font-size: 0;
    line-height: 0;
    text-align: center;
    box-shadow: none;
    background-color: $c-mdl-bg;
    transition: opacity 0.25s, visibility 0.25s, z-index 0.25s;
    &:before {
        @include pseudo;
        display: inline-block;
        position: static;
        height: 100%;
        width: 0px;
        vertical-align: middle;
    }
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg $c-scrollbar-track-bg;
    &::-webkit-scrollbar {
        width: 8px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $c-scrollbar-track-bg;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-scrollbar-thumb-bg;
        border-radius: 4px;
    }
}
.mdl-wrap.active {
    z-index: 1000 !important;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s, visibility 0.25s, z-index 0s;
    @supports (-webkit-overflow-scrolling: touch) {
        cursor: pointer;
    }
}
.mdl {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 280px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    text-align: left;
    @include roboto-condensed-regular;
    background-color: $c-bg;
    box-shadow: 0 5px 30px 0 $c-mdl-shadow;
    /*	@media all and (width < 320px){
		margin-top: 0;
		margin-bottom: 0;
	}*/
    /*	&--full-screen-less-640 {
		@media all and (width < 640px){
			width: 100vw !important;
			min-height: 100vh;
			background-color: #f9f9f9;
			@include mdl-full-screen;
		}
	}
	&--full-screen-less-320 {
		@media all and (width <= 325px){
			@include mdl-full-screen;
		}
	}*/
}
.touchevents .mdl,
.touchevents .mdl__head,
.touchevents .mdl__body {
    cursor: pointer;
}
.mdl__head {
    position: relative;
    padding: 12px 50px 12px 20px;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    line-height: 19px;
    color: $c-title;
    background-color: $c-light;
    &:after {
        @include pseudo;
        @include rlg;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}
.mdl__body {
    padding: 20px 20px 32px;
}
.mdl__title {
}
.mdl__txt {
    font-size: 16px;
    line-height: 19px;
    & + & {
        margin-top: 15px;
    }
}
.mdl__block {
    & + & {
        margin-top: 30px;
    }
    .btn + .btn {
        margin-left: 20px;
    }
}
.mdl__txt + .mdl__block,
.mdl__block + .mdl__txt {
    margin-top: 29px;
}
.mdl__foot {
}
.mdl__cross {
    z-index: 10;
    position: absolute;
    top: 11px;
    right: 9px;
    width: 21px;
    height: 21px;
    transform: rotate(45deg);
    background-color: transparent;
    cursor: pointer;
}
.mdl__cross:before,
.mdl__cross:after {
    @include pseudo;
    @include size-center;
    background-color: $c-font-active;
}
.mdl__cross:before {
    width: 11px;
    height: 1px;
}
.mdl__cross:after {
    width: 1px;
    height: 11px;
}
.mdl__cross:hover:before,
.mdl__cross:hover:after {
    background-color: $c-font-active-hover;
}
