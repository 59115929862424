.sub-menu {
    z-index: 10;
    position: relative;
    @include flex-between-center;
    /*height: 50px;*/
    padding-right: 16px;
    background-color: $c-submenu-bg;
    color: $c-submenu-font;
    @media all and (width < 960px) {
        padding-right: 12px;
    }
}
.sub-menu-left {
    @include clearfix;
}
.sub-menu-left__item {
    float: left;
    display: inline-block;
    vertical-align: top;
}
.sub-menu-left__item-btn {
    position: relative;
    display: block;
    height: 50px;
    font-size: 17px;
    line-height: 20px;
    font-family: 'roboto-condensed', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    padding: 16px 20px 14px 59px;
    border-left: 1px solid $c-submenu-item-bg-hover;
    color: $c-submenu-font;
    &:hover {
        background-color: $c-submenu-item-bg-hover;
    }
    @media all and (width < 960px) {
        height: 55px;
        padding-top: 37px;
        padding-left: 12px;
        padding-right: 12px;
        text-align: center;
        font-size: 10px;
        line-height: 12px;
    }
    @media all and (width < 640px) {
        height: 40px;
        width: 45px;
        font-size: 0;
        line-height: 0;
        padding: 0;
    }
}
.sub-menu-right {
    @include clearfix;
}
.sub-menu-right__item {
    position: relative;
    float: left;
    & + & {
        margin-left: 16px;
        @media all and (width < 640px) {
            margin-left: 8px;
        }
    }
}
.sub-menu-btn {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 5px 12px;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid $c-main-menu-btn-border;
    border-radius: 5px;
    color: $c-main-menu-btn-font;
    background-color: transparent;
    &:hover {
        border-color: $c-main-menu-btn-border-hover;
    }
    &--icon {
        padding-left: 36px;
        @media all and (width < 640px) {
            width: 32px;
            height: 32px;
            padding: 0;
            font-size: 0;
            line-height: 0;
        }
    }
}

.sub-menu__dropdown-menu {
    display: none;
    position: absolute;
    width: 200px;
    &.active {
        display: block;
    }
    &.under {
        top: calc(100% + 13px);
    }
    &.left {
        right: 0;
    }
    &.right {
        left: 0;
    }
    li {
        a {
            display: block;
            padding: 9px 12px 8px;
            border-radius: 5px;
            font-size: 16px;
            line-height: 19px;
            text-decoration: none;
            color: $c-light;
            background-color: $c-submenu-bg;

            &:hover {
                background-color: $c-submenu-item-bg-hover;
            }
        }
    }
    li + li {
        margin-top: 4px;
    }
}
