.pill {
    display: inline-block;
    padding: 5px 16px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 12px;
    color: $c-pill-font-default;
    background-color: $c-pill-bg-default;
    &.active {
        color: $c-pill-font-active;
        background-color: $c-pill-bg-active;
    }
    &.alert {
        color: $c-pill-font-alert;
        background-color: $c-pill-bg-alert;
    }
    &.warning {
        color: $c-pill-font-warning;
        background-color: $c-pill-bg-warning;
    }
    &.success {
        color: $c-pill-font-success;
        background-color: $c-pill-bg-success;
    }
}

.pill--sm {
    padding: 3px 7px;
    font-size: 12px;
    line-height: 14px;
}
