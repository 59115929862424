.fileupload {
    position: relative;
    width: 210px;
    &.single {
        width: 108px;
    }
    &--replies {
        width: auto;
    }
}
.fileupload__label {
    cursor: pointer;
    display: block;
    position: relative;
    padding: 60px 30px 27px;
    border: 1px dashed $c-font-active;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: $c-font-active;
    &:hover {
        border-color: $c-font-active-hover;
        color: $c-font-active-hover;
    }
}
.fileupload.single {
    .fileupload__label {
        width: 100%;
        height: 108px;
        padding: 60px 10px 0;
    }
}
.fileupload--complete.single {
    .fileupload__label {
        display: none;
    }
}
.fileupload--in-progress {
    .fileupload__label {
        border-color: $c-font-active-hover;
        color: $c-font-active-hover;
    }
}
.fileupload__progress {
    display: none;
    position: relative;
    margin-top: 24px;
    padding-right: 22px;
    &--replies {
        margin-top: 20px;
    }
}
.fileupload--in-progress {
    .fileupload__progress {
        display: block;
    }
}
.fileupload__bar-outer {
    overflow: hidden;
    height: 8px;
    border-radius: 4px;
    background-color: $c-bg-disabled;
}
.fileupload__bar-inner {
    height: 100%;
    width: 0;
    background-color: $c-form-item-border;
}
.fileupload__btn-remove {
    z-index: 10;
    position: absolute;
    width: 21px;
    height: 21px;
    padding: 0;
    transform: rotate(45deg);
    background-color: $c-transparent;
    &:before,
    &:after {
        @include pseudo;
        @include size-center;
        background-color: $c-font-active;
    }
    &:before {
        height: 11px;
        width: 1px;
    }
    &:after {
        width: 11px;
        height: 1px;
    }
    &:hover {
        &:before,
        &:after {
            background-color: $c-font-active-hover;
        }
    }
}
.fileupload__progress {
    .fileupload__btn-remove {
        top: -7px;
        right: 0;
    }
}
.fileupload__file {
    .fileupload__btn-remove {
        @include size-vertical-center;
        right: 6px;
    }
}
.single .fileupload__file {
    .fileupload__btn-remove {
        z-index: 10;
        top: 2px;
        bottom: auto;
        right: 2px;
    }
}
.fileupload__files-wrap {
    margin-top: 32px;
    &--replies {
        margin-top: 0;
        @include flex-start-start;
        flex-wrap:wrap;
    }
}
.fileupload--in-progress,
.fileupload--complete {
    .fileupload__files-wrap--replies {
        padding-top: 12px;
    }
}
.fileupload__file {
    position: relative;
    display: block;
    height: 36px;
    padding: 6px 44px 6px 12px;
    border: 1px solid $c-form-item-border-disabled;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: $c-default;
    background-color: $c-fileupload-item-bg;
    & + & {
        margin-top: 8px;
    }
}
.fileupload--replies,
.reply__files-wrap {
    .fileupload__file {
        margin-top: 8px;
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.fileupload.single {
    .fileupload__file {
        overflow: hidden;
        display: none;
        height: 108px;
        padding: 65px 10px 0;
        border: 1px solid $c-form-item-border;
        border-radius: 5px;
        &:before {
            z-index: 5;
            @include pseudo;
            top: 0;
            left: 0;
            height: 70px;
            width: 100%;
            background: linear-gradient(to bottom, $c-bg 0px, transparent 100%);
        }
    }
}
.fileupload--complete.single {
    .fileupload__file {
        display: block;
    }
}
.fileupload:not(.single) {
    .fileupload__file-name {
        display: block;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.fileupload.single {
    .fileupload__file-name {
        @include flex-center-start;
        font-size: 14px;
        line-height: 16px;
    }
    .fileupload__file-name-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.fileupload--replies {
    .fileupload__file-name {
        max-width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.fileupload__file-img {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
