.empty-state {
    @include flex-center-center;
    width: 263px;
    height: 267px;
    padding: 20px;
    border: 7px solid #ebf1f7;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;

    &__center {
        max-height: 100%;
        overflow: hidden;
    }

    &__icon {
        width: 72px;
        height: 72px;
        color: #5e7891;
    }

    &__desc {
        margin-top: 20px;
        font-family: 'roboto-condensed';
        font-size: 16px;
        line-height: 22px;
        color: #5e7891;

        @media all and (width < 960px) {
            font-size: 15px;
            line-height: 20px;
        }

        .link-txt {
            line-height: 24px;
        }
    }

    &__title {
        font-weight: 600;

        &:not(:only-child) {
            margin-bottom: 4px;
        }
    }

    &__text {
    }
}

.empty-state__text + .link-txt {
    margin-top: 8px;
}
