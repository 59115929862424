.ui-datepicker {
    z-index: 2000 !important;
    width: 294px;
    padding: 0;
    border: 1px solid $c-form-item-border !important;
    border-radius: 5px;
    background-color: $c-form-item-bg;
    transform: translateY(2px);
}
.ui-datepicker .ui-datepicker-header {
    height: auto;
    padding: 15px 0 10px;
    border: none;
    border-radius: 5px 5px 0 0;
    background-color: $c-form-item-bg;
}
.ui-datepicker .ui-datepicker-title {
    margin-left: auto;
    margin-right: auto;
    width: 230px;
    &:after {
        @include pseudo;
        position: static;
        display: table;
        width: 100%;
        font-size: 0;
        line-height: 0;
        clear: both;
    }
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    cursor: pointer;
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    margin: auto;
    width: 12px;
    height: 12px;
    border-top: 1px solid $c-font-active;
    border-left: 1px solid $c-font-active;
    border-bottom: none;
    border-right: none;
    border-radius: 0;
    background-color: $c-datepicker-bg;
}
.ui-datepicker .ui-datepicker-prev:not(.ui-state-disabled):hover,
.ui-datepicker .ui-datepicker-next:not(.ui-state-disabled):hover {
    border-top-color: $c-font-active-hover;
    border-left-color: $c-font-active-hover;
}
.ui-datepicker .ui-datepicker-prev.ui-state-disabled,
.ui-datepicker .ui-datepicker-next.ui-state-disabled {
    border-left-color: $c-form-item-border-disabled;
    border-top-color: $c-form-item-border-disabled;
    opacity: 1;
}
.ui-datepicker .ui-datepicker-prev {
    left: 14px;
    transform: rotate(-45deg);
}
.ui-datepicker .ui-datepicker-next {
    right: 14px;
    transform: rotate(135deg);
}
.ui-datepicker-calendar {
    margin: 0 !important;
}
.ui-datepicker-calendar body {
    padding: 10px;
}
.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
    padding: 0;
    &:first-child {
        width: 39px;
    }
    &:last-child {
        width: 39px;
    }
}
.ui-datepicker-calendar th {
    &:first-child {
        padding-left: 6px;
    }
    &:last-child {
        padding-right: 6px;
    }
}
.ui-datepicker-calendar td {
    &:first-child {
        padding-left: 15px;
    }
    &:last-child {
        padding-right: 14px;
    }
}
.ui-datepicker-calendar th {
    width: 30px;
    height: 22px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    line-height: 22px;
    @include roboto-condensed-regular;
    text-transform: uppercase;
    color: $c-form-item-font;
    background-color: $c-form-item-bg-disabled;
}
.ui-datepicker-calendar td {
    padding: 2px 3px;
}
.ui-datepicker-calendar tr:first-child td {
    padding-top: 4px !important;
}
.ui-datepicker-calendar tr:last-child td {
    padding-bottom: 10px !important;
}
.ui-datepicker-calendar td a,
.ui-datepicker-calendar td span {
    display: block;
    height: 32px;
    padding: 0;
    border: none !important;
    font-size: 16px;
    line-height: 30px;
    @include roboto-condensed-regular;
    text-align: center;
    color: $c-form-item-font;
}
.ui-datepicker-calendar .ui-datepicker-today a,
.ui-datepicker-calendar .ui-datepicker-today span {
    border-radius: 50%;
}
.ui-datepicker-calendar .ui-datepicker-current-day {
    padding: 0 3px;

    a,
    span {
        height: 34px;
    }
}
.ui-datepicker-calendar .ui-datepicker-current-day a,
.ui-datepicker-calendar .ui-datepicker-current-day span {
    border-radius: 50%;
}
.ui-datepicker-calendar .ui-state-disabled {
    opacity: 1;
}
.ui-datepicker-calendar .ui-state-disabled span {
    color: $c-form-item-border-disabled !important;
    background-color: $c-form-item-bg !important;
}
.ui-datepicker-calendar .ui-state-default {
    color: $c-form-item-font;
    background-color: $c-form-item-bg;
    &:hover {
        color: $c-font-active;
        background-color: $c-form-item-bg;
    }
}
.ui-datepicker-calendar .ui-state-highlight {
    position: relative;
    &:hover {
        color: $c-font-active;
    }
}
.ui-datepicker-calendar .ui-state-active {
    color: $c-form-item-font !important;
    background-color: $c-form-item-bg !important;
    border: 1px solid $c-datepicker-date-border-active-chosen !important;
    &:hover {
        color: $c-font-active !important;
    }
}
.ui-datepicker-calendar .ui-datepicker-today a {
    color: $c-datepicker-date-font-today !important;
    background-color: $c-datepicker-date-bg-today !important;
    &.ui-state-active:hover {
        color: $c-datepicker-date-font-today !important;
    }
}
.ui-datepicker-calendar .chosen-date span {
    color: $c-datepicker-date-added-class-font !important;
    background-color: $c-datepicker-date-added-class-bg !important;
}
.ui-datepicker-current-day.ui-datepicker-today a {
    color: $c-form-item-font !important;
    background-color: $c-form-item-bg !important;
    &.ui-state-active:hover {
        color: $c-font-active !important;
    }
}
.ui-datepicker-month + .select2-container,
.ui-datepicker-year + .select2-container {
    height: 36px;
    border: none;
}
.ui-datepicker-year + .select2-container {
    width: 95px;
}
.ui-datepicker-month + .select2-container {
    width: 123px;
}
.ui-datepicker-month + .select2-container {
    float: left;
}
.ui-datepicker-year + .select2-container {
    float: right;
}
.ui-datepicker-calendar.week {
    tr.datepicker-current-week:first-child td {
        padding-top: 3px !important;
    }
    tr.datepicker-current-week:last-child td {
        padding-bottom: 9px !important;
    }
    td {
        padding: 2px 0;
    }
    td:first-child {
        padding-left: 12px;
    }
    td:last-child {
        padding-right: 12px;
    }
    td span,
    td a {
        padding: 0 3px;
    }
    .ui-datepicker-today {
        padding: 2px 3px;
    }
    .ui-datepicker-today span,
    .ui-datepicker-today a {
        /* padding: 0; */
    }
    td:first-child span,
    td:first-child a,
    .datepicker-next-other span,
    .datepicker-next-other a {
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
    }
    td:last-of-type span,
    td:last-of-type a,
    .datepicker-prev-other span,
    .datepicker-prev-other a {
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
    }
    .ui-state-default:hover {
        color: $c-font-active !important;
    }
    tr:hover {
        color: $c-font-active !important;

        .ui-datepicker-today {
            /* padding: 2px 0; */
        }

        &:first-child .ui-datepicker-today {
            padding-top: 4px;
        }

        td:not(.ui-state-disabled) span,
        td:not(.ui-state-disabled) a {
            color: $c-font-active !important;
            /* background-color:$c-datepicker-date-bg-today !important; */
        }

        .ui-state-disabled span {
        }
    }
    tr:not(.datepicker-current-week):hover {
        .ui-datepicker-today span,
        .ui-datepicker-today a {
            color: $c-form-item-bg !important;
            /* padding: 0 3px; */
            /* border-radius: 0; */
            background-color: $c-datepicker-date-bg-today !important;
            /* border: 1px solid $c-datepicker-date-bg-today !important; */
        }
    }
}
.ui-datepicker-calendar .datepicker-current-week {
    .ui-datepicker-today {
        padding: 1px 0;
    }
    &:first-child .ui-datepicker-today {
        padding-top: 4px;
    }
    .ui-datepicker-today span,
    .ui-datepicker-today a {
        /* padding: 0 3px; */
        color: $c-form-item-font !important;
        border-radius: 0;
        background-color: $c-form-item-bg !important;
    }
    .ui-datepicker-current-day span,
    .ui-datepicker-current-day a {
        border-radius: 0;
    }
    td {
        padding: 1px 0;
        &:first-child {
            padding-left: 11px;
            &:after {
                left: 11px !important;
            }
        }
        &:last-child {
            padding-right: 11px;
            &:after {
                right: 11px !important;
            }
        }
    }
    td:first-child span,
    td:first-child a {
        border-left: 1px solid $c-datepicker-date-bg-today !important;
        border-top: 1px solid $c-datepicker-date-bg-today !important;
        border-bottom: 1px solid $c-datepicker-date-bg-today !important;
    }
    td:last-child span,
    td:last-child a {
        border-right: 1px solid $c-datepicker-date-bg-today !important;
        border-top: 1px solid $c-datepicker-date-bg-today !important;
        border-bottom: 1px solid $c-datepicker-date-bg-today !important;
    }
    .datepicker-next-other span,
    .datepicker-next-other a {
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
        border-left: 1px solid $c-datepicker-date-bg-today !important;
    }
    .datepicker-next-other:last-child {
        span,
        a {
            padding-left: 2px;
        }
    }
    .datepicker-prev-other span,
    .datepicker-prev-other a {
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        border-right: 1px solid $c-datepicker-date-bg-today !important;
    }
    .datepicker-prev-other:first-child {
        span,
        a {
            padding-right: 2px;
        }
    }
    .ui-state-active {
        border: none !important;
    }
    a.ui-state-default,
    span.ui-state-default {
        height: 34px;
        border-top: 1px solid $c-datepicker-date-bg-today !important;
        border-bottom: 1px solid $c-datepicker-date-bg-today !important;
        /* color:$c-datepicker-date-font-today !important; */
        /* background-color:$c-datepicker-date-bg-today !important; */
    }
    .ui-state-disabled span {
    }
    .ui-datepicker-today {
        position: relative;

        a {
            z-index: 1;
            color: #fff !important;
            background-color: transparent !important;
        }
        &::after {
            @include pseudo;
            left: 3px;
            top: 1px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: #6393c4 !important;
        }
        &:last-child,
        &:first-child,
        &.datepicker-prev-other,
        &.datepicker-next-other {
            &::after {
                width: 38px;
                left: 0;
            }
        }
        &:first-child.datepicker-prev-other {
            a {
                color: $c-form-item-font !important;
                background-color: $c-form-item-bg !important;
            }
            &::after {
                display: none;
            }
        }
        &:last-child.datepicker-next-other {
            a {
                color: $c-form-item-font !important;
                background-color: $c-form-item-bg !important;
            }
            &::after {
                display: none;
            }
        }
    }
}

/* button shows with `showOn` option on plugin initialization.
In safari mobile focus event doesn't work, so this button added
to trigger event */
.ui-datepicker-trigger {
    display: none;
    /* display: block; */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
    cursor: pointer;
}

/*=============================================
=            Datepicker Date Range            =
=============================================*/
.ui-datepicker-calendar.range {
    .ui-state-default.in-range {
        color: $c-font-active !important;
    }
    td.dp-highlight {
        &.ui-datepicker-current-day a,
        &.ui-datepicker-current-day span,
        &.ui-datepicker-today a {
            border-radius: 0;
        }
        a.ui-state-active {
            border: none !important;
            color: #5e7891 !important;
            background-color: #fff !important;
        }
        padding-left: 0;
        padding-right: 0;
        padding: 1px 0;
        a.ui-state-default {
            height: 34px;
            padding-left: 3px;
            padding-right: 3px;
            color: $c-form-item-font !important;
            background-color: $c-form-item-bg !important;
            border-top: 1px solid $c-datepicker-date-border-active-chosen !important;
            border-bottom: 1px solid $c-datepicker-date-border-active-chosen !important;
            &:hover {
                color: $c-font-active !important;
            }
        }
        &:first-child {
            padding-left: 14px;
            &:after {
                left: 14px !important;
            }
        }
        &--first {
            padding-left: 2px;
        }
        &--first,
        &:first-child {
            a.ui-state-default {
                padding-left: 0;
                border-left: 1px solid $c-datepicker-date-border-active-chosen !important;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }
        }
        &:last-child {
            padding-right: 13px;
            &:after {
                right: 13px !important;
            }
        }
        &--last {
            padding-right: 2px;
        }
        &--last,
        &:last-child {
            a.ui-state-default {
                padding-right: 0;
                border-right: 1px solid $c-datepicker-date-border-active-chosen !important;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }
        }
        &.ui-datepicker-today {
            position: relative;

            a {
                z-index: 1;
                color: #fff !important;
                background-color: transparent !important;
            }
            &::after {
                @include pseudo;
                left: 2px;
                top: 1px;
                width: 34px;
                height: 34px;
                border-radius: 50%;
                background-color: #6393c4 !important;
            }
        }
    }

    .dp-highlight--first.dp-highlight--last {
        padding-left: 3px;
        padding-right: 3px;

        a.ui-state-default {
            padding-left: 0;
            padding-right: 0;
            color: $c-form-item-font !important;
            background-color: $c-form-item-bg !important;
        }
        &::after {
            display: none !important;
        }
    }

    tr:first-child td.dp-highlight {
        padding-top: 3px !important;
    }
    tr:last-child td.dp-highlight {
        padding-bottom: 9px !important;
    }
}
/*=====  End of Datepicker Date Range  ======*/
/*=====  End of Datepicker Date Range  ======*/

/*===========================================
=            Datepicker Multiple            =
===========================================*/

.ui-datepicker-calendar.multiple {
    .ui-datepicker-current-day {
        /* padding: 2px 3px; */
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .ui-datepicker-today a {
        color: $c-datepicker-date-font-today !important;
        background-color: $c-datepicker-date-bg-today !important;
    }
    a.ui-state-active {
        height: 32px;
        border: none !important;
        &:hover {
            color: $c-font-active !important;
        }
    }

    .dp-selected {
        padding: 1px 2px;

        a {
            height: 34px;
            border-radius: 50%;
            color: $c-form-item-font !important;
            background-color: $c-form-item-bg !important;
            border: 1px solid $c-datepicker-date-border-active-chosen !important;
            &:hover {
                color: $c-font-active !important;
                background-color: $c-form-item-bg !important;
            }
        }
        &:first-child {
            padding-left: 14px;
        }
        &:last-child {
            padding-right: 13px;
        }
    }
    tr:last-child .dp-selected {
        padding-bottom: 9px !important;
    }
    tr:first-child .dp-selected {
        padding-top: 3px !important;
    }
}

/*=====  End of Datepicker Multiple  ======*/
