@font-face {
    font-family: 'roboto-condensed';
    src: url('../fonts/roboto-condensed-regular.woff2') format('woff2'),
        url('../fonts/roboto-condensed-regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'roboto-condensed';
    src: url('../fonts/roboto-condensed-regular-italic.woff2') format('woff2'),
        url('../fonts/roboto-condensed-regular-italic.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'roboto-condensed';
    src: url('../fonts/roboto-condensed-bold.woff2') format('woff2'),
        url('../fonts/roboto-condensed-bold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-regular.woff2') format('woff2'),
        url('../fonts/roboto-regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-regular-italic.woff2') format('woff2'),
        url('../fonts/roboto-regular-italic.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-light.woff2') format('woff2'),
        url('../fonts/roboto-light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-light-italic.woff2') format('woff2'),
        url('../fonts/roboto-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-medium.woff2') format('woff2'),
        url('../fonts/roboto-medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-medium-italic.woff2') format('woff2'),
        url('../fonts/roboto-medium-italic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-bold.woff2') format('woff2'),
        url('../fonts/roboto-bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-bold-italic.woff2') format('woff2'),
        url('../fonts/roboto-bold-italic.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}
