.timepicker {
    display: none;
    position: absolute;
    left: -1px;
    /*height: 102px;*/
    /*width: 294px;*/
    padding: 12px;
    font-size: 0;
    line-height: 0;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    background-color: $c-dropdown-bg;
    white-space: nowrap;
    &.active {
        display: table !important;
    }
}
.timepicker__colon {
    display: inline-block;
    vertical-align: top;
    margin-top: 18px;
    margin-left: 11px;
    margin-right: 11px;
    /*width: 2px;*/
    font-size: 32px;
    line-height: 32px;
    font-family: 'roboto-condensed';
    font-weight: 300;
    color: $c-default;
}
.timepicker__arrows-wrap {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}
.timepicker__arrow {
    position: relative;
    display: block;
    width: 32px;
    height: 50%;
    background-color: $c-light-on-dark;
    &:hover {
        background-color: $c-dark-on-light;
    }
    &:before {
        @include pseudo;
        @include size-center;
        width: 10px;
        height: 6px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }
    &--plus {
        &:before {
            border-bottom: 6px solid $c-dark-on-light;
        }
        &:hover {
            &:before {
                border-bottom-color: $c-light-on-dark;
            }
        }
    }
    &--minus {
        &:before {
            border-top: 6px solid $c-dark-on-light;
        }
        &:hover {
            &:before {
                border-top-color: $c-light-on-dark;
            }
        }
    }
}
.timepicker__ampm-wrap {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
    height: 72px;
    width: 36px;
    border-radius: 3px;
    overflow: hidden;
}
.timepicker__ampm {
    display: block;
    height: 50%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    line-height: 36px;
    font-family: 'roboto-condensed';
    text-transform: uppercase;
    text-align: center;
    color: $c-dark-on-light;
    background-color: $c-light-on-dark;
    &:not(.active):hover {
        color: $c-light-on-dark;
        background-color: $c-dark-on-light;
    }
    &.current {
        color: $c-light-on-dark;
        background-color: $c-dark-on-light;
    }
}

/*=========================================
=            Timepicker period            =
=========================================*/

.timepicker.popover {
    padding: 0;
    .popover__body {
        padding: 20px;
        text-align: center;
    }
    &.active {
        display: block !important;
    }
    .btn {
        margin-top: 10px;
    }
}

.timepicker-period__wrap {
    @include flex-start-start;
}

.timepicker-mini__period-divider {
    width: 15px;
    margin-top: 20px;
    color: #5e7891;
    font-family: roboto-condensed;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.timepicker-mini {
    display: none;
    /*height: 102px;*/
    /*width: 294px;*/
    font-size: 0;
    line-height: 0;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    background-color: $c-dropdown-bg;
    white-space: nowrap;
}
.timepicker-mini__colon {
    display: inline-block;
    /* vertical-align: top; */
    margin-top: 21px;
    margin-left: 3px;
    margin-right: 4px;
    /*width: 2px;*/
    font-size: 20px;
    line-height: 24px;
    font-family: 'roboto-condensed';
    font-weight: 400;
    color: $c-default;
}
.timepicker-mini__arrow {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background-color: transparent;
    /* &:hover {
		background-color: $c-dark-on-light;
	} */
    &:before {
        @include pseudo;
        @include size-center;
        width: 7px;
        height: 6px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
    }
    &--plus {
        top: 0;
        &:before {
            border-bottom: 6px solid $c-dark-on-light;
        }
        &:hover {
            &:before {
                border-bottom-color: $c-light-on-dark;
            }
        }
    }
    &--minus {
        bottom: 0;
        &:before {
            border-top: 6px solid $c-dark-on-light;
        }
        &:hover {
            &:before {
                border-top-color: $c-light-on-dark;
            }
        }
    }
    &.hours {
        left: 10px;
    }
    &.minutes {
        left: 43px;
    }
    &.ampm {
        left: 77px;
    }
}
