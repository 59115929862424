.form-cell {
    position: relative;
    &--it-fix {
        display: inline-table;
        font-size: 0;
        line-height: 0;
        & > * {
            display: inline-block;
        }
    }
    &--t {
        display: table;
        font-size: 0;
        line-height: 0;
        & > * {
            display: inline-block;
        }
    }
    &--t-100p {
        display: table;
        width: 100%;
        font-size: 0;
        line-height: 0;
        & > * {
            display: inline-block;
        }
    }
    &--hor {
        display: inline-flex;
        justify-content: start;
        align-items: center;
    }
    &--between {
        @include flex-between-start;
    }
    &--if-between {
        @include flex-between-start;
        display: inline-flex;
        vertical-align: top;
    }
    &--end {
        @include flex-end-start;
    }
    &--start {
        @include flex-start-start;
    }
    &.under {
        margin-top: 12px;
        &:first-child {
            margin-top: 0;
        }
    }
    @media all and (width < 960px) {
        display: block;
    }
}
/* .form-cell {
	@include flex-start-start;
	position: relative;
	&.under {
		margin-top: 12px;
		&:first-child {
			margin-top: 0;
		}
		@media all and (width < 960px) {
			margin-top: 14px;
		}
	}

	@media all and (width < 960px) {
		display: block;
	}
} */
.form-cell__slash {
    display: inline-block;
    vertical-align: top;
    margin: 10px 12px 0;
    font-size: 17px;
    line-height: 27px;
    color: #536169;
    @media all and (width < 1440px) {
        margin: 8px 11px 0 11px;
        font-size: 16px;
        line-height: 26px;
    }
    @media all and (width < 960px) {
        @include none-size-center;
        margin: auto;
        font-size: 15px;
        line-height: 22px;
    }
}
.form-cell__dash {
    font-size: 17px;
    line-height: 27px;
    color: #536169;
    @media all and (width < 1440px) {
        font-size: 16px;
        line-height: 26px;
    }
    @media all and (width < 960px) {
        font-size: 15px;
        line-height: 22px;
    }
}
.form-row:first-of-type,
.form-row--first {
    .form-cell--mt {
        margin-top: 17px;
        @media all and (width < 1280px) {
            margin-top: 6px;
        }
        @media all and (width < 960px) {
            margin-top: 13px;
            &:first-child,
            &:first-of-type {
                margin-top: 5px;
            }
        }
    }
}
.signature {
    .form-cell--signature {
        @media all and (width < 960px) {
        }
    }
    .form-cell--signature-date {
        margin-left: 28px;
        @media all and (width < 960px) {
            float: left;
            margin-left: 0;
            margin-top: 14px;
        }
    }
}
.form-cell--checkbox-nested {
    padding-left: 34px;
    padding-top: 12px;
    padding-bottom: 12px;

    @media all and (width < 960px) {
        padding-bottom: 8px;
    }
}

.form-cell__title {
    font-size: 16px;
    line-height: 22px;
    @include roboto-condensed-bold;
    color: $c-default;
    @media all and (width < 960px) {
        font-style: 15px;
        line-height: 20px;
    }
}
