.message {
    position: relative;
    padding: 21px 41px 20px 64px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    font-family: 'roboto-condensed';
    font-weight: 400;
    @media all and (width < 960px) {
        padding: 18px 20px 20px 65px;
        font-size: 15px;
        line-height: 20px;
    }
    &--inter {
        display: none;
        padding-right: 64px;
        &.active {
            display: block;
        }
    }
}
.message__cross {
    cursor: pointer;
    z-index: 10;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 11px;
    height: 11px;
    padding: 0;
    background-color: transparent;
    transform: rotate(45deg);
    &:before,
    &:after {
        @include pseudo;
        @include size-center;
        background-color: $c-font-active;
    }
    &:before {
        width: 1px;
        height: 100%;
    }
    &:after {
        width: 100%;
        height: 1px;
    }
    &:hover {
        &:before,
        &:after {
            background-color: $c-font-active-hover;
        }
    }
}
.message--success {
    border-color: $c-success;
    &.message--bg {
        background-color: $c-success-bg;
    }
}
.message--warning {
    border-color: $c-warning;
    &.message--bg {
        background-color: $c-warning-bg;
    }
}
.message--alert {
    border-color: $c-alert;
    &.message--bg {
        background-color: $c-alert-bg;
    }
}
.message {
    p + p {
        margin-top: 9px;
        @media all and (width < 960px) {
            margin-top: 13px;
        }
    }
}
