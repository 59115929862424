.tabs-to-accord {
    @include flex-start-stretch;
    width: 100%;
    border: 1px solid $c-form-item-border-disabled;
    font-size: 16px;
    line-height: 22px;
    background-color: $c-bg;
}
.tabs-to-accord__tabs-wrap {
    width: 280px;
    border-right: 1px solid $c-form-item-border-disabled;
    @media all and (width < 960px) {
        display: none;
    }
}
.tabs-to-accord__content-wrap {
    width: 100%;
}
.tabs-to-accord__tab {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 17px 40px 18px;
    color: $c-default;
    background-color: $c-bg;
    &:not(.current):hover {
        color: $c-tabs-to-accord-font-hover;
    }
    &.current {
        cursor: auto;
        background-color: $c-bg-disabled;
    }
    & + & {
        border-top: 1px solid $c-form-item-border-disabled;
    }
    &:last-child {
        border-bottom: 1px solid $c-form-item-border-disabled;
    }
}
.tabs-to-accord__content {
    @media all and (width < 960px) {
        display: block !important;
    }
}
.tabs-to-accord__head {
    display: none;
    padding: 17px 20px 18px;
    font-size: 15px;
    line-height: 22px;
    border-bottom: 1px solid $c-form-item-border-disabled;
    @media all and (width < 960px) {
        display: block;
        cursor: pointer;
    }
    &.active {
        background-color: $c-bg-disabled;
    }
}
.tabs-to-accord__content:last-child {
    .tabs-to-accord__head:not(.active),
    .tabs-to-accord__body {
        border-bottom: none;
    }
}
.tabs-to-accord__body {
    display: none;
    @media all and (width >= 960px) {
        display: block !important;
    }
    @media all and (width < 960px) {
        border-bottom: 1px solid $c-form-item-border-disabled;
    }
}
.tabs-to-accord__item {
    position: relative;
    padding: 17px 45px 18px 90px;
    &:nth-child(odd) {
        background-color: $c-tabs-to-accord-item-bg;
    }
    @media all and (width < 960px) {
        padding: 18px 20px 19px 55px;
        font-size: 15px;
        line-height: 20px;
    }
    .icon-svg-wrap {
        position: absolute;
        top: 17px;
        left: 40px;
        width: 24px;
        height: 24px;
        color: $c-success;
        @media all and (width < 960px) {
            top: 18px;
            left: 20px;
        }
    }
}
