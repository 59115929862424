*[class^='title-'] {
    margin-top: 0;
    margin-bottom: 0;
    @include roboto-condensed-regular;
    color: $c-title;
}
.title-1 {
    font-size: 28px;
    line-height: 36px;
    @media all and (width < 960px) {
        font-size: 24px;
        line-height: 30px;
    }
    @media all and (width < 640px) {
        font-size: 20px;
        line-height: 26px;
    }
}
.title-2 {
    font-size: 24px;
    line-height: 30px;
    @media all and (width < 960px) {
        font-size: 20px;
        line-height: 26px;
    }
    @media all and (width < 640px) {
        font-size: 18px;
        line-height: 24px;
    }
}
.title-3 {
    font-size: 20px;
    line-height: 26px;
    @media all and (width < 960px) {
        font-size: 18px;
        line-height: 24px;
    }
    @media all and (width < 640px) {
        font-size: 17px;
        line-height: 22px;
    }
}
