.indicator {
    display: inline-block;
    padding: 3px 11px;
    font-size: 14px;
    line-height: 16px;
    @include roboto-condensed-regular;
    color: $c-default;
    background-color: $c-light-on-dark;
    border-radius: 11px;
    @media all and (width < 640px) {
        &--top-tabs {
            margin-top: -1px;
            padding-top: 1px;
            padding-bottom: 1px;
            padding-left: 6px;
            padding-right: 6px;
            font-size: 9px;
            line-height: 11px;
        }
    }
    &--sm {
        font-size: 12px;
        line-height: 12px;
        padding: 1px 5px 3px;
        border-radius: 12px;
    }
    &.warning {
        color: $c-bg;
        background-color: $c-warning;
    }
    &.alert {
        color: $c-bg;
        background-color: $c-alert;
    }
    &.success {
        color: $c-bg;
        background-color: $c-success;
    }
}
.indicator.dot {
    display: block;
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    & + & {
        margin-left: 10px;
    }
    @media all and (width < 640px) {
        margin-left: 4px;
        width: 8px;
        height: 8px;
    }
}
.indicator-group {
    display: table;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    .indicator {
        display: inline-block;
        vertical-align: top;
    }
}
.tab-nav {
    & > .indicator,
    & > .indicator-group {
        z-index: 10;
        margin-left: 12px;
        @media all and (width < 960px) {
            position: absolute;
            top: 3px;
            right: -2px;
            margin-left: auto;
        }
    }
    .indicator:not(.dot) {
        @media all and (width < 960px) {
            padding: 1px 6px 2px;
            font-size: 11px;
            line-height: 11px;
        }
    }
    .indicator.dot {
        margin-top: 5px;
        @media all and (width < 960px) {
            margin-top: 3px;
            width: 7px;
            height: 7px;
            & + & {
                margin-left: 7px;
            }
        }
    }
    @media all and (width < 960px) {
        .indicator.dot + .indicator.dot {
            margin-left: 7px;
        }
    }
}
.dropdown__item {
    .indicator {
        margin-left: 20px;
    }
}
