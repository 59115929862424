.btn {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    min-height: 36px;
    padding: 6px 16px;
    font-size: 16px;
    line-height: 22px;
    @include roboto-condensed-regular;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    &:disabled {
        cursor: default;
    }
}
/************BTN-TYPES****************/
.btn--icon-left {
    padding-left: 50px;
}
.btn--icon-right {
    padding-right: 50px;
}
.btn--icon {
    min-width: 36px;
}
.btn--drop,
.btn--drop-split {
    &:after {
        @include pseudo;
        @include size-vertical-center;
        right: 10px;
        height: 6px;
        width: 8px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid $c-btn-drop-arrow;
        transition: transform 0.25s;
    }
    &.active:after {
        transform: rotate(180deg);
    }
    &:disabled {
        &:after {
            border-top-color: $c-btn-drop-arrow-disabled;
        }
    }
}
.btn--drop {
    padding-right: 40px;
}
.btn--prev-to-drop-split {
    padding-right: 40px;
}
.btn--drop-split {
    width: 31px;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    border-color: $c-btn-primary-border;
    border-left-color: $c-btn-split-border-left !important;
    &:not(:disabled):hover {
        border-color: $c-btn-primary-border-hover;
    }
}
.btn--group {
    font-family: 'roboto-condensed';
    border: 1px solid $c-btn-group-border;
    color: $c-btn-group-font;
    background-color: $c-btn-group-bg;
    box-shadow: none;
    &:not(.current):hover {
        background-color: $c-btn-group-bg-hover;
    }
    &.current {
        cursor: auto;
        color: $c-btn-group-font-current;
        background-color: $c-btn-group-bg-current;
    }

    @media all and (width < 960px) {
        padding: 5px 12px 4px;
        font-size: 15px;
        min-height: 35px;
    }
}
.btn-group {
    .btn:not(:last-child):not(.btn--drop-split):not(.btn--drop) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .btn:not(:first-child):not(.btn--drop-split):not(.btn--drop) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .btn--drop-split:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .btn--drop-split {
        &:disabled {
            border-color: $c-btn-split-bg-disabled;
            border-left-color: $c-btn-split-border-left-disabled !important;
        }
        /*		&:before{
			@include pseudo;
			@include size-center;
			width: 100%;
			height: 100%;
		}
		&:hover:before {
			background-color: $c-btn-split-bg-hover;
		}
		&:disabled:before {
			background-color: $c-btn-split-bg-disabled;
		}
		&:first-child:before {
			display: none;
		}*/
    }
    .btn--group:not(:last-child) {
        border-right: none;
    }
    .btn--group:not(:first-child) {
        /*border-left:none;*/
    }
}

/*******COLORS-STYLES********/
.btn--primary {
    border-color: $c-btn-primary-border;
    color: $c-btn-primary-font;
    background-color: $c-btn-primary-bg;
    &:not(:disabled):hover {
        border-color: $c-btn-primary-border-hover;
        color: $c-btn-primary-font-hover;
        background-color: $c-btn-primary-bg-hover;
    }
    &:not(:disabled).hovered {
    }
    &:disabled {
        border-color: $c-btn-primary-border-disabled;
        color: $c-btn-primary-font-disabled;
        background-color: $c-btn-primary-bg-disabled;
    }
}
.btn--secondary {
    border-color: $c-btn-secondary-border;
    color: $c-btn-secondary-font;
    background-color: $c-btn-secondary-bg;
    &:not(:disabled):hover {
        border-color: $c-btn-secondary-border-hover;
        color: $c-btn-secondary-font-hover;
        background-color: $c-btn-secondary-bg-hover;
    }
    &:not(:disabled).hovered {
    }
    &:disabled {
        border-color: $c-btn-secondary-border-disabled;
        color: $c-btn-secondary-font-disabled;
        background-color: $c-btn-secondary-bg-disabled;
    }
}
.btn--transparent {
    color: $c-btn-alt-font;
    border: none;
    background-color: transparent;
    &:not(:disabled):hover {
        color: $c-btn-alt-font-hover;
        background-color: transparent;
    }
    &:disabled {
        color: $c-btn-secondary-font-disabled;
        background-color: transparent;
    }
}
/**********************************************/
/* .left-menu__footer {
	.btn {
		margin-top: 10px;
	}
} */
/**************************************/
.signature {
    .btn {
        margin-left: auto;
        @media all and (width < 960px) {
            float: right;
            margin-top: 39px;
        }
    }
}
.btn--badge {
    position: relative;

    .indicator {
        position: absolute;
        right: 11px;
        top: 16px;
        transform: translate(100%, -100%);
    }
}
.btn--badge-hidden {
    .indicator {
        display: none;
    }
}
