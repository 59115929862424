.ui-kit-content {
    position: static !important;
    margin-left: 0;
    min-height: 100vh;
    padding: 50px 0;
    background-color: $c-main-container-bg;
}
.ui-kit-nav.active + .ui-kit-content {
    margin-left: 280px;
    padding: 50px;
}
.ui-kit-page {
    display: none;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    &.active {
        display: block;
    }
}
.ui-kit-block {
    position: relative;
    width: 100%;
    & + & {
        margin-top: 40px;
    }
}
.ui-kit-block {
    & + & {
        margin-top: 35px;
    }
    &--spinner {
        overflow: hidden;
        width: 500px;
        height: 300px;
        padding: 20px;
        border: 1px solid $c-form-item-border;
        border-radius: 5px;
    }

    &--to-reply {
        @include flex-between-center;
        padding: 10px 22px 10px 30px;
    }

    &--reply-section {
        border: 1px solid $c-form-item-border;
        border-radius: 5px;
        overflow: hidden;
        background-color: #fff;
    }
    &--dark {
        background-color: $c-body-font;
        padding: 20px 5px;
    }
    &--block {
        display: block !important;
    }
}
.ui-kit-column {
}
.ui-kit-row {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    & + & {
        margin-top: 25px;
    }
    & > * {
        display: inline-flex;
        margin-right: 20px;
        margin-top: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    .btn,
    .btn-alt {
        white-space: nowrap;
    }
}
.ui-kit-description-code + .ui-kit-row {
    margin-top: 35px;
}
.ui-kit-cell {
    display: block;
    margin-top: 20px;
    & > *:not(:first-child):not(.ui-kit-block) {
        margin-top: 20px;
    }
}
.ui-kit-column {
    & > .ui-kit-cell {
        &:not(:first-child) {
            padding-top: 20px;
        }
    }
}
.ui-kit-label {
    display: block;
    @include fz16;
    color: #000;
}
.ui-kit-title {
    margin: 0;
    @include roboto-bold;
    font-size: 22px;
    line-height: 32px;
    text-transform: uppercase;
}
.ui-kit-title + * {
    margin-top: 10px;
}
.ui-kit-title:not(:first-child) {
    margin-top: 60px;
}

.ui-kit-subtitle {
    margin: 0;
    @include roboto-bold;
    font-size: 17px;
    line-height: 27px;
    text-transform: uppercase;
}
.ui-kit-subtitle + * {
    margin-top: 10px;
}
.ui-kit-subtitle:not(:first-child) {
    margin-top: 60px;
}


.ui-kit-title + .accordion {
    margin-top: 35px;
}

.ui-kit-description-txt {
    margin-top: 35px;
    font-size: 16px;
    line-height: 24px;
    @include roboto-regular;
    color: #000;
    a {
        color: $c-font-active;
        &:hover {
            text-decoration: underline;
        }
    }
}
.accordion__body {
    .ui-kit-description-txt {
        margin-top: 0;
        .list-un {
            li::before {
                background-color: #000;
            }
        }
    }
}
.ui-kit-description-code {
    position: relative;
    margin-top: 25px;
    overflow-y: auto;
    padding: 0 30px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    background-color: $c-bg-disabled;
    & + & {
        margin-top: 25px;
    }
    pre,
    code {
        margin: 0;
        padding: 0;
    }
}
.ui-kit-btn-copy {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 4px 10px 6px;
    border-radius: 0 0 0 5px;
    background-color: $c-btn-primary-bg;
    color: #fff;
    opacity: 0.8;
}
.ui-kit-value-additional-values {
    margin-top: -10px;
    @include flex-start-start;
    flex-wrap:wrap;
}
.ui-kit-value {
    margin-top: 30px;
    margin-right: 170px;
    &__title {
        @include roboto-regular;
        text-transform: uppercase;
        font-size: 15px;
    }
    &__data-wrap {
        margin-top: 5px;
        @include flex-start-center;
    }
    &__class {
        white-space: nowrap;
        font-size: 16px;
        line-height: 26px;
        color:#9DBCDA;
    }
    &__color-quadro {
        margin-left: 30px;
        min-width: 48px;
        height: 48px;
        &--lvl-1 {
            background-color: $c-table-lvl-1-row-bg;
        }
        &--lvl-2 {
            background-color: $c-table-lvl-2-row-bg;
        }
        &--lvl-3 {
            background-color: $c-table-lvl-3-row-bg;
        }
        &--lvl-4 {
            background-color: $c-table-lvl-4-row-bg;
        }
        &--lvl-5 {
            background-color: $c-table-lvl-5-row-bg;
        }
    }
    &__color-value {
        margin-left: 30px;
        color:#9DBCDA;
    }
}
