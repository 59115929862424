.tab-nav {
    position: relative;
    display: block;
    box-sizing: border-box;
    /* float: left; */
    margin-bottom: -1px;
    margin-left: 22px;
    height: 52px;
    /*	min-width: 170px;*/
    padding-top: 15px;
    padding-right: 8px;
    padding-left: 44px;
    border-top: 1px solid $c-border;
    border-bottom: 1px solid $c-border;
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
    color: $c-font-active;
    background-color: $c-bg-disabled;
    font-family: 'roboto-condensed';
    font-weight: 600;
    text-decoration: none;
    @media all and (width < 960px) {
        min-width: 40px;
        padding-left: 0;
        padding-right: 0;
        font-size: 0;
        line-height: 0;
    }
    &:hover {
        color: $c-font-active-hover;
    }
    &:first-child {
        margin-left: 0;
    }
    &:before {
        left: -29px;
        background-image: url(../images/inactive_tab_left.svg);
    }
    &:after {
        right: -29px;
        background-image: url(../images/inactive_tab_right.svg);
    }
}

.tab-nav--indicate {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media all and (width < 960px) {
        font-size: 0;
    }
}

.tab-nav--text {
    padding: 4px 8px 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    text-transform: none;
    @media all and (width < 960px) {
        padding-top: 5px;
        font-size: 15px;
        line-height: 21px;
    }
}

.tab-nav__hint-text {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: $c-hint;
    font-style: italic;
    white-space: nowrap;
    @media all and (width < 960px) {
        font-size: 15px;
        line-height: 21px;
    }
}

.tab-nav:hover {
    .tab-nav__hint-text {
        color: $c-hint;
    }
}

.tab-nav.current {
    z-index: 100 !important;
    cursor: default;
    border-bottom-color: $c-bg;
    color: $c-default;
    background-color: $c-bg;
    &:before {
        background-image: url(../images/active_tab_left.svg);
    }
    &:after {
        background-image: url(../images/active_tab_right.svg);
    }
}

.tab-nav:before,
.tab-nav:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    width: 29px;
    height: 52px;
    border-bottom: 1px solid #bbd7eb;
    background-position: 0 0;
    background-size: cover;
}

.tab-nav.current:before,
.tab-nav.current:after {
    border-bottom: none;
}

.dragging {
    .tab-nav {
        cursor: grab !important;
    }
}
