.form-section {
    margin-top: 24px;

    &--first {
        margin-top: 0;
    }

    @media all and (width < 960px) {
        margin-top: 20px;
    }
}

.form__title + .form-section {
    margin-top: 15px;

    @media all and (width < 960px) {
        margin-top: 12px;
    }
}

.form__title {
    margin-top: 28px;

    &:first-child {
        margin-top: 0;
    }

    @media all and (width < 960px) {
        margin-top: 24px;
    }
}
