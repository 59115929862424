.form-hint {
    margin-top: 6px;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 400;
    font-style: italic !important;
    color: $c-hint;
    @media all and (width < 960px) {
        font-size: 15px !important;
    }
    &.error {
        display: none;
        font-style: normal !important;
        color: $c-alert !important;
        &.active {
            display: block;
        }
    }
}
