/**[data-position] {
	position: relative;
}*/
.btn:hover {
    .popover {
        position: fixed;
        display: block;
        z-index: 1000;
    }
}
.popover {
    display: none;
    z-index: -1;
    position: absolute;
    width: 280px;
    max-width: 100vw;
    border: 1px solid $c-form-item-border;
    border-radius: 5px;
    text-align: left;
    @include roboto-condensed-regular;
    background-color: $c-light;
    box-shadow: 0 1px 2px 0 $c-mdl-shadow;
    &.active {
        z-index: 1000 !important;
        display: block;
    }
    &:before {
        @include pseudo;
        z-index: 10;
        width: 11px;
        height: 11px;
        background-color: $c-light;
    }
    &:after {
        @include pseudo;
        z-index: 12;
        height: 10px;
        width: 20px;
        background-color: $c-light;
    }
    &.under {
        &:before {
            top: -6px;
            border-left: 1px solid $c-form-item-border;
            border-top: 1px solid $c-form-item-border;
            transform: rotate(45deg);
        }
        &:after {
            top: 0;
        }
    }
    &.above {
        &:before {
            bottom: -6px;
            border-left: 1px solid $c-form-item-border;
            border-bottom: 1px solid $c-form-item-border;
            box-shadow: 0 1px 2px 0 $c-mdl-shadow;
            transform: rotate(-45deg);
        }
        &:after {
            bottom: 0;
        }
    }
    &.v-neutral {
        top: 50%;
        transform: translateY(-50%);
        &:before,
        &:after {
            display: none;
        }
    }
    &.left {
        &:before {
            right: 45px;
        }
        &:after {
            right: 41px;
        }
    }
    &.right {
        &:before {
            left: 45px;
        }
        &:after {
            left: 41px;
        }
    }
    &.h-neutral {
    }
}
.popover--tooltip {
    max-width: 250px;
    width: auto;
    font-size: 16px;
    line-height: 19px;
    color: $c-default;
    word-break: break-all;
    pointer-events: none;
    &.above,
    &.under {
        &:before,
        &:after {
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.left,
    &.right {
        &:before {
            @include size-vertical-center;
            border-left: 1px solid $c-form-item-border;
            border-top: 1px solid $c-form-item-border;
        }
    }
    &.neutral {
        left: 50% !important;
        transform: translateX(-50%);
        &:before,
        &:after {
            display: none;
        }
    }
    &.left {
        &:before {
            left: auto;
            right: -6px;
            transform: rotate(135deg);
        }
    }
    &.right {
        &:before {
            left: -6px;
            right: auto;
            transform: rotate(-45deg);
        }
    }
    &.left,
    &.right {
        &:after {
            @include size-vertical-center;
            @include pseudo;
            z-index: 12;
            height: 20px;
            width: 10px;
            background-color: $c-light;
        }
    }
    &.left {
        &:after {
            right: 0;
            left: auto;
        }
    }
    &.right {
        &:after {
            left: 0;
            right: auto;
        }
    }

    & > * {
        z-index: 20;
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 6px 15px;
        border-radius: 4px;
        background-color: $c-light;
    }
}
.touchevents .popover,
.touchevents .popover__head,
.touchevents .popover__body {
    cursor: pointer;
}
.popover__head {
    position: relative;
    padding: 12px 50px 12px 20px;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    line-height: 19px;
    color: $c-title;
    background-color: $c-light;
    &:after {
        @include pseudo;
        @include rlg;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}
.popover__body {
    padding: 20px 20px 30px;
}
.popover__title {
}
.popover__txt {
    font-size: 16px;
    line-height: 19px;
    white-space: normal;
    color: $c-default;
    & + & {
        margin-top: 15px;
    }
}
.popover__block {
    & + & {
        margin-top: 30px;
    }
    .btn + .btn {
        margin-left: 15px;
    }
}
.popover__txt + .popover__block,
.popover__block + .popover__txt {
    margin-top: 27px;
}
.popover__foot {
}
.popover__cross {
    z-index: 10;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 21px;
    height: 21px;
    transform: rotate(45deg);
    background-color: transparent;
    cursor: pointer;
    @media all and (width < 640px) {
    }
}
.popover__cross:before,
.popover__cross:after {
    @include pseudo;
    @include size-center;
    background-color: $c-font-active;
}
.popover__cross:before {
    width: 11px;
    height: 1px;
}
.popover__cross:after {
    width: 1px;
    height: 11px;
}
.popover__cross:hover:before,
.popover__cross:hover:after {
    background-color: $c-font-active-hover;
}

.popover--year-period {
    width: 288px;

    .form-row {
        @media all and (width < 960px) {
            display: flex;
        }
    }
}
