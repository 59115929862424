.footer {
    padding-bottom: 70px;
    background-color: $c-submenu-bg;
    @media all and (width < 640px) {
        padding-bottom: 0;
    }
}
.footer__top {
    @include flex-start-center;
    padding: 14px 16px 19px;
    background-color: $c-main-menu-bg;
    @media all and (width < 640px) {
        display: block;
        padding: 17px 20px 20px;
    }
}
.footer__logo {
    display: block;
    width: 146px;
    height: 30px;
}
.footer__copyright {
    margin-left: 15px;
    font-size: 15px;
    line-height: 19px;
    color: $c-light;
    @media all and (width < 640px) {
        margin-left: 0;
        margin-top: 15px;
    }
}
.footer__nav {
    @include clearfix;
    margin-left: auto;
    @media all and (width < 640px) {
        margin-top: 24px;
    }
}
.footer__nav-item {
    float: left;
    @media all and (width < 640px) {
        float: none;
    }
    & + & {
        margin-left: 25px;
        @media all and (width < 640px) {
            margin-left: 0;
            margin-top: 8px;
        }
    }
}
.footer__nav-link {
    display: block;
    font-size: 15px;
    line-height: 18px;
    color: $c-footer-link-font;
    &:hover {
        color: $c-bg-disabled;
    }
    @media all and (width < 640px) {
        font-size: 14px;
        line-height: 19px;
    }
}
