.spinner-wrap {
    z-index: 10000;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $c-spinner-bg;
    &.active {
        display: block;
    }
    &.local {
        z-index: 100;
        position: absolute;
    }
}
.spinner {
    z-index: 10003;
    @include size-center;
    position: absolute;
    width: 84px;
    height: 84px;
}
.spinner:after {
    @include pseudo;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
    border-color: $c-spinner transparent $c-spinner transparent;
    transform: scale(1) translate(0);
    animation: spinner 1s linear infinite;
}
@keyframes spinner {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
