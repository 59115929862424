.draggable {
    position: relative;
    overflow: hidden;
    user-select: none;
    /* cursor: grab; */
    .draggable-wrap {
        position: relative;
        left: 0px;
    }
    .draggable-inner {
        display: inline-block;
    }
}
