.signature {
    @include flex-start-center;
    width: 100%;
    padding: 40px;
    border: 1px solid $c-signature-border;
    border-radius: 5px;
    @media all and (width < 960px) {
        display: block;
        width: 280px;
        padding: 16px 14px 22px;
        &:after {
            content: '';
            display: block;
            height: 0;
            width: 100%;
            clear: both;
        }
    }
}
