.form-item-wrap {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: 36px;
    border-width: 1px;
    border-style: solid;
    border-color: $c-form-item-border;
    background-color: $c-bg;
    border-radius: 5px;
    &.under {
        margin-top: 12px;
        &:first-child {
            margin-top: 0;
        }
    }
    &.next {
        margin-left: 20px;
        &:first-child {
            margin-left: 0;
        }
    }
    &.focused {
        border-color: $c-form-item-border-focused;
    }
    &.disabled {
        border-color: $c-form-item-border-disabled;
        background-color: $c-form-item-bg-disabled;
    }
    &.error {
        border-color: $c-form-item-border-error;
    }
    &.warning {
        border-color: $c-form-item-border-warning;
    }
    &.success {
        border-color: $c-form-item-border-success;
    }
    &--textarea {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
    }
}
@media all and (width < 960px) {
    .form-label + .form-item-wrap {
        margin-top: 8px;
    }
}
.form-item-wrap--group {
    /*	display: block;
	overflow: hidden;*/
}
.form-item-wrap {
    &.date,
    &.time {
        &:before {
            /*			cursor:pointer;
			@include pseudo;
			@include size-vertical-center;
			right: 0;
			width: 39px;
			height: 100%;
			border-radius: 0 4px 4px 0;
			background-color: $c-form-item-bg-disabled;*/
        }
    }
    &.date {
        width: 132px;
        position: relative;
        &.focused:after {
            @include pseudo;
            width: calc(100% + 2px);
            height: 1px;
            bottom: -1px;
            left: -1px;
        }
    }
    &.date.week {
        width: 165px;
    }
    &.date.range {
        width: 215px;
    }
    &.date.multiple {
        width: 215px;
    }
    &.time {
        /* z-index: 100; */
    }
    &.time.big {
        width: 132px;
    }
    &.time.period {
        width: 184px;
    }
}
.form-item-wrap--timepicker {
    display: inline-block;
    vertical-align: top;
    width: 94px;
    height: 72px;
    border-color: $c-form-item-border;
    overflow: hidden;
}
.form-item-wrap--timepicker-mini {
    display: flex;
    vertical-align: top;
    width: 110px;
    height: 68px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: $c-form-item-border;
    overflow: hidden;
}
.form-item-wrap--header-select {
    margin-left: 27px;
    width: 203px;
    height: 32px;
    border-color: $c-main-menu-btn-border;
    background-color: $c-main-menu-bg;
    &.focused {
        border-color: $c-main-menu-btn-border;
    }
    &:hover {
        border-color: $c-main-menu-btn-border-hover;
    }
    @media all and (width < 960px) {
        margin-left: 12px;
        width: 56px;
    }
}
.form-item-wrap--search {
    padding-right: 35px;
}
.left-menu__title {
    & + .form-item-wrap {
        margin-top: 16px;
    }
}
.signature {
    .form-item-wrap--signature {
        width: 387px;
        @media all and (width < 960px) {
            width: auto;
        }
    }
    .form-item-wrap--signature-date {
        width: 107px;
        border-color: $c-form-item-bg-disabled !important;
        background-color: $c-form-item-bg-disabled;
    }
}

.form-item-wrap--year-period {
    background-color: $c-btn-primary-bg;
}

.form-item-wrap--period + .form-item-wrap--period {
    margin-left: 20px;
}
.form-item-wrap--period-year {
    width: 76px;
}

.form-item-wrap--period-period {
    width: 150px;
}
.form-item-wrap--dynamic-height {
/*     height: 36px; */
    height: auto;
    min-height: 36px;
}
