.i {
    position: relative;
    width: 192px;
    @media all and (width < 1920px) {
        width: 168px;
    }
    @media all and (width < 1680px) {
        width: 144px;
    }
    @media all and (width < 1440px) {
        width: 128px;
    }
    @media all and (width < 1280px) {
        width: 96px;
    }
    @media all and (width < 960px) {
        width: 64px;
    }
    @media all and (width < 640px) {
        width: 32px;
    }
}
