.form-label {
    display: block;
    margin-top: 6px;
    margin-right: 12px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'roboto-condensed', sans-serif;
    text-align: left;
    text-transform: none;
    color: $c-default;
    @media all and (width < 960px) {
        margin-top: 0;
        margin-right: 0;
        line-height: 19px;
    }
    @media all and (width < 960px) {
        font-size: 15px;
    }
    &--inline {
        display: inline;
    }
    &--next-to-item {
        margin-left: 20px;
    }
    &--nowrap {
        white-space: nowrap;
    }
    &--absolute-right {
        position: absolute;
        right: 0;
        top: 0;
    }
}
