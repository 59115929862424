.form-item {
    display: block;
    height: 100%;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    line-height: 19px;
    @include roboto-condensed-regular;
    color: $c-form-item-font;
    background-color: transparent;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @media all and (width < 960px) {
        padding-left: 12px;
        padding-right: 12px;
    }
    @media all and (width < 960px) {
        font-size: 15px;
    }
    &:disabled {
        cursor: default !important;
        color: $c-form-item-font-disabled;
    }
}
.form-item--no-border {
    border:none;
}
.form-item-wrap--icon-right {
    .form-item {
        padding-right: 0;
    }
}
.form-item-wrap--group {
    .form-item {
        width: auto;
    }
}
.form-item-wrap.date {
    .form-item {
        z-index: 10;
        position: relative;
        cursor: pointer;
        margin-left: -1px;
        height: calc(100% + 1px);
        padding-bottom: 3px !important;
        padding-right: 39px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 32px;
    }
    input.datepicker.week,
    input.datepicker.range,
    input.datepicker.multiple {
        opacity: 0;
        position: absolute;
        z-index: 15;
    }
}
.form-item-wrap.time {
    & > .form-item {
        z-index: 10;
        cursor: pointer;
        height: 100%;
        padding-right: 39px;
    }
}
.form-item--timepicker {
    padding-left: 16px;
    padding-right: 32px;
    font-size: 32px;
    line-height: 32px;
    font-family: 'roboto-condensed';
    color: $c-default;
}
.form-item--timepicker-mini {
    padding-left: 0;
    padding-right: 0;
    width: 21px;
    font-size: 20px;
    line-height: 24px;
    font-family: 'roboto-condensed';
    color: $c-default;
}
.form-item--timepicker-mini.ampm {
    margin-left: 11px;
    width: 25px;
    text-transform: lowercase;
}
.form-item {
    padding-bottom: 2px;
}
.form-item--textarea {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 27px;
    @media all and (width < 1440px) {
        line-height: 26px;
    }
    @media all and (width < 960px) {
        padding: 0 18px 0 12px;
        line-height: 22px;
    }
    &.scroll-custom {
        padding-right: 10px;
    }
}
.form-item-wrap--signature {
    .form-item {
        font-size: 26px;
        font-family: 'dancing-script';
        font-weight: 400;
        font-style: normal;
    }
}
.form-item--year-period {
    color: $c-btn-primary-font;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    cursor: pointer;
}
.form-item--dynamic-height {
    resize: none;
    overflow: hidden;
/*     height: auto; */
    height: 34px;
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 8px;
    font-weight: 400;
/*     white-space: pre-wrap;
    word-wrap: break-word; */
}
