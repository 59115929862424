.ui-kit-menu {
    margin-top: 0;
    margin-bottom: 0;
    width: 280px;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 0;
    background-color: $c-ui-kit-nav-bg;
    @media all and (width <=639px) {
        width: 320px;
        padding-bottom: 25px;
    }
    &--lvl-1-list {
        transform: translateX(-280px);
        transition: transform 0.3s;
        &.active {
            transform: translateX(0) !important;
        }
        @media all and (width <= 639px) {
            transform: translateX(-320px);
        }
    }
}
.med,
.min {
    .ui-kit-menu__item-wrap {
        &--lvl-2 {
            &:not(.active):hover .ui-kit-menu__item--lvl-2 {
                box-shadow: 2px 0 0 $c-ui-kit-menu-item-hover-bg;
            }
            &.active:hover .ui-kit-menu__item--lvl-2 {
                box-shadow: 2px 0 0 $c-ui-kit-menu-item-active-bg;
            }
        }
    }
}
@media all and (width > 639px) {
    .med {
        .ui-kit-menu {
            width: 134px;
            padding-top: 0;
            &--lvl-1-list {
                transform: translateX(-134px);
            }
        }
        .ui-kit-menu__lvl-2 {
            left: 134px;
        }
        .ui-kit-menu__item-wrap {
            &--lvl-2 {
                position: relative;
            }
        }
        .ui-kit-menu__return-to-lvl-1-btn {
            height: 40px;
            font-size: 0;
        }
    }
    .min {
        .ui-kit-menu {
            width: 60px;
            padding-top: 0;
            &--lvl-1-list {
                transform: translateX(-60px);
            }
        }
        .ui-kit-menu__lvl-2 {
            left: 60px;
        }
        .ui-kit-menu__item-wrap {
            &--lvl-2 {
                position: relative;
            }
        }
        .ui-kit-menu__return-to-lvl-1-btn {
            height: 40px;
            padding: 0;
            font-size: 0;
            &:before {
                @include none-size-horizontal-center;
                margin-left: -2px;
                width: 6px;
            }
        }
    }
}

.ui-kit-menu__item {
    display: block;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: 'opensans', sans-serif;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer;
    color: $c-ui-kit-menu-item-font;

    &.current {
        background-color: $c-ui-kit-menu-item-active-bg;
        &:before {
            @include pseudo;
            top: 0;
            bottom: 0;
            left: 0;
            width: 4px;
            background-color: $c-ui-kit-menu-item-active-before-bg;
        }
    }
    &--arrow {
        &:after {
            @include pseudo;
            @include size-vertical-center;
            right: 20px;
            height: 10px;
            border-left-width: 6px;
            border-left-style: solid;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left-color: $c-ui-kit-menu-item-arrow-bg;
        }
    }
    &--lvl-2 {
        padding-bottom: 10px;
        &.active {
            &:before {
                top: 10px;
                bottom: 0;
            }
        }
    }
}

.no-touchevents {
    .ui-kit-menu__item {
        &:hover {
            color: $c-ui-kit-menu-item-hover-font;
        }
        &:not(.current):hover {
            background-color: $c-ui-kit-menu-item-hover-bg;
        }
    }
}
.med {
    .ui-kit-menu__item--lvl-2 {
        &:after {
            border-left-color: $c-ui-kit-menu-item-arrow-bg;
        }
    }
}

@media all and (width > 639px) {
    .med {
        .ui-kit-menu__item {
            padding-top: 55px;
            padding-bottom: 17px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 13px;
            font-weight: 700;
            text-align: center;
            &--arrow {
                &:after {
                    top: 25px;
                    bottom: auto;
                    right: 16px;
                }
            }
            &--lvl-2 {
                &:after {
                    @include pseudo;
                    top: 25px;
                    right: 14px;
                    right: 20px;
                    height: 10px;
                    border-left-width: 6px;
                    border-left-style: solid;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                }
                &.active {
                    &:before {
                        top: 0;
                    }
                }
            }
        }
    }
    .min {
        .ui-kit-menu__item {
            height: 60px;
            padding: 0;
            font-size: 0;
            &--arrow {
                &:after {
                    display: none;
                }
            }
            &--lvl-2 {
                &.active {
                    &:before {
                        top: 0;
                    }
                }
            }
        }
    }
}
.ui-kit-submenu {
    display: none;
    padding-bottom: 30px;
    &.active {
        display: block;
    }
}
.ui-kit-submenu__item {
    display: block;
    position: relative;
    padding-left: 60px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'opensans', sans-serif;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer;
    color: $c-ui-kit-menu-item-font;
    opacity: 0.8;

    &.current {
        background-color: $c-ui-kit-menu-item-hover-bg;
        &:before {
            @include pseudo;
            top: 0;
            bottom: 0;
            left: 0;
            width: 4px;
            background-color: $c-disabled;
        }
    }
}

.no-touchevents {
    .ui-kit-submenu__item {
        &:hover {
            color: $c-ui-kit-menu-item-hover-font;
        }
        &:not(.current):hover {
            background-color: $c-ui-kit-menu-item-hover-bg;
        }
    }
}
