.scroll-custom {
    scrollbar-width: thin;
    scrollbar-color: $c-scrollbar-thumb-bg-uikit $c-scrollbar-track-bg-uikit;
}
.scroll-custom::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
}
.scroll-custom::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: $c-scrollbar-track-bg-uikit;
}
.scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: $c-scrollbar-thumb-bg-uikit;
}
