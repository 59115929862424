.left-menu-wrap {
    overflow: hidden;
    position: relative;
    width: 0;
    transition: width 0.25s;
}
.main-field {
    &.active {
        .left-menu-wrap {
            width: 244px;
            height: 100%;
        }
    }
}
.left-menu {
    position: relative;
    /* left: -244px; */
    width: 244px;
    min-height: 100%;
    /* padding: 29px 20px 36px; */
    border: 1px solid $c-border;
    border-radius: 5px 0 0 5px;
    transition: left 0.25s;
    border-right: 1px solid $c-border;
    background-color: #fbfdfe;
}
.main-field {
    .left-menu {
        border-left: none;
        border-top: none;
        border-bottom: none;
    }
    &.active {
        .left-menu {
            left: 0;
        }
    }
}
.left-menu__header {
    padding: 21px 20px 23px;
    border-bottom: 1px solid $c-border;

    &--filter {
        padding-top: 26px;
        padding-bottom: 0;
        border-bottom: none;
    }
}
.left-menu__title {
    font-size: 20px;
    line-height: 24px;
    font-family: 'roboto-condensed';
    font-weight: 400;
    text-transform: uppercase;
    color: $c-title;
}
.left-menu__title-2 {
    text-transform: none;
}
.left-menu__content {
    margin: 0 -20px;
    padding: 18px 20px 15px;
}
.left-menu__footer {
    padding: 17px 20px 36px;
    border-top: 1px solid $c-border;
}
.left-menu__link {
    display: block;
    padding: 12px 40px 12px 20px;
    font-family: 'roboto-condensed';
    font-size: 16px;
    line-height: 19px;
    color: #5e7891;
    &:hover {
        color: #6ea7e2;
    }
    &.current {
        background-color: #ebf1f7;
    }
}
.code {
    padding: 40px;
    background-color: #fefefe;
}
