.main-field {
    @include flex-start-stretch;
    width: 100%;
    border: 1px solid $c-border;
    border-radius: 5px;
    overflow: hidden;
    background-color: $c-bg;
}
.main-field__left {
    z-index: 10;
    position: relative;
    transition: width 0.25s;
}
.main-field__right {
    position: relative;
    width: 100%;
}
.main-field__content {
    padding: 28px 20px 32px;
}
.main-field__btn {
    position: absolute;
    top: 8px;
    right: -36px;
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 0 5px 5px 0;
    background-color: $c-light-on-dark;
    &:hover {
        background-color: $c-dark-on-light;
    }
    &:before {
        @include pseudo;
        @include size-center;
        width: 20px;
        height: 13px;
        border-top: 1px solid $c-dark-on-light;
        border-bottom: 1px solid $c-dark-on-light;
    }
    &:after {
        @include pseudo;
        @include size-center;
        width: 20px;
        height: 1px;
        background-color: $c-dark-on-light;
    }
    &:hover {
        &:before {
            border-top-color: $c-light-on-dark;
            border-bottom-color: $c-light-on-dark;
        }
        &:after {
            background-color: $c-light-on-dark;
        }
    }
}
/*.main-field {
	&.active {
		.main-field__btn {
			&:before {
				left: 13px;
				transform: rotate(-45deg);
			}
		}
	}
}*/
