.form-item-prepend {
    @include flex-center-center;
    /*float: left;*/
    height: 100%;
    min-width: 40px;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
    border-radius: 5px 0 0 5px;
    background-color: $c-form-item-bg-disabled;
}
