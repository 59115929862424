.ui-kit-nav {
    z-index: 1000;
    width: 280px;
    height: 100vh;
    background-color: $c-ui-kit-nav-bg;
    &--pos {
        position: fixed;
        top: 0;
        left: -280px;
        &.active {
            left: 0;
        }
    }
}
.ui-kit-nav__btn {
    position: absolute;
    right: -40px;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 0 0 5px 0;
    background-color: $c-form-item-border;
    &:before {
        @include pseudo;
        @include size-vertical-center;
        right: 17px;
        width: 14px;
        height: 14px;
        border-left: 1px solid $c-default;
        border-top: 1px solid $c-default;
        transform: rotate(135deg);
    }
}
.active {
    .ui-kit-nav__btn {
        &:before {
            left: 15px;
            transform: rotate(-45deg);
        }
    }
}
