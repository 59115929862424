.txt {
    font-size: 18px;
    line-height: 26px;
    @include roboto-condensed-regular;
    color: $c-default;
    @media all and (width < 1280px) {
        font-size: 17px;
        line-height: 22px;
    }
    @media all and (width < 960px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media all and (width < 640px) {
        font-size: 15px;
        line-height: 20px;
    }
}
.title-1 + .txt {
    margin-top: 22px;
    @media all and (width < 1280px) {
        margin-top: 24px;
    }
    @media all and (width < 960px) {
        margin-top: 22px;
    }
    @media all and (width < 640px) {
        margin-top: 19px;
    }
}
.title-2 + .txt {
    margin-top: 24px;
    @media all and (width < 1280px) {
        margin-top: 26px;
    }
    @media all and (width < 960px) {
        margin-top: 23px;
    }
    @media all and (width < 640px) {
        margin-top: 19px;
    }
}
.title-3 + .txt {
    margin-top: 21px;
    @media all and (width < 1280px) {
        margin-top: 23px;
    }
    @media all and (width < 960px) {
        margin-top: 19px;
    }
    @media all and (width < 640px) {
        margin-top: 15px;
    }
}
