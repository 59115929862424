.checkbox,
.radio {
    position: relative;
    display: block;
    min-width: 20px;
    min-height: 20px;
    &.under {
        margin-top: 12px;
        &:first-child {
            margin-top: 0;
        }
    }
    &.next {
        margin-left: 20px;
    }
}
.checkbox__pseudo-check,
.radio__pseudo-check {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: $c-form-item-border;
    background-color: $c-form-item-bg;
}
.radio__pseudo-check {
    border-radius: 50%;
}
label.checkbox:hover .checkbox__pseudo-check,
.checkbox__pseudo-check:hover,
label.radio:hover .radio__pseudo-check,
.radio__pseudo-check:hover {
    border-color: $c-form-item-border-focused;
}
input[type='radio']:checked + .radio__pseudo-check,
input[type='ckeckbox']:checked + .checkbox__pseudo-check {
}
.checkbox {
    input:checked + .checkbox__pseudo-check {
        &:before {
            @include pseudo;
            top: 4px;
            left: 3px;
            width: 12px;
            height: 6px;
            border-left: 1px solid $c-form-item-border-focused;
            border-bottom: 1px solid $c-form-item-border-focused;
            transform: rotate(-45deg);
        }
    }
}
.radio {
    input:checked + .radio__pseudo-check {
        &:before {
            @include pseudo;
            @include size-center;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $c-form-item-border-focused;
        }
    }
}
input:disabled + .checkbox__pseudo-check {
    background-color: $c-form-item-bg-disabled !important;
    border-color: $c-form-item-border-disabled !important;
    cursor: auto;
    &:before {
        /*		border-left-color:;
		border-bottom-color:*/
    }
}
input:disabled + .radio__pseudo-check {
    background-color: $c-form-item-bg-disabled !important;
    border-color: $c-form-item-border-disabled !important;
    cursor: auto;
    &:before {
    }
}
.checkbox__txt,
.radio__txt {
    cursor: pointer;
    display: block;
    padding-left: 34px;
    font-size: 16px;
    line-height: 19px;
    @include roboto-condensed-regular;
    text-align: left;
    @media all and (width < 960px) {
        font-size: 15px;
    }
}
.form-item-wrap--group {
    .checkbox__txt,
    .radio__txt {
        padding-right: 6px;
        color: $c-default;
    }
}
input:disabled ~ .checkbox__txt,
label + .checkbox__txt,
input:disabled ~ .radio__txt,
label + .radio__txt {
    cursor: auto;
}

.checkbox.error,
.radio.error {
    .checkbox__pseudo-check,
    .radio__pseudo-check {
        border-color: $c-form-item-border-error;
    }
    input:checked + .checkbox__pseudo-check {
        &:before {
            border-left-color: $c-form-item-border-error;
            border-bottom-color: $c-form-item-border-error;
        }
    }
    input:checked + .radio__pseudo-check {
        &:before {
            background-color: $c-form-item-border-error;
        }
    }
}

.checkbox--partly + .checkbox__pseudo-check {
    &:before {
        @include pseudo;
        top: 4px;
        left: 3px;
        width: 12px;
        height: 6px;
        border-bottom: 1px solid $c-form-item-border-focused;
        transform: rotate(-45deg);
    }
}
