.accordion {
    font-size: 16px;
    line-height: 19px;
    font-family: 'roboto-condensed';
    font-weight: 400;
    &.type-1 + &.type-1 {
        margin-top: 16px;
    }
    &.type-2 + &.type-2 {
        margin-top: 20px;
    }
    &.type-3 + &.type-3 {
        margin-top: 36px;
    }
    &.type-3.active + &.type-3 {
        margin-top: 11px;
    }
    &.type-4 + &.type-4 {
        margin-top: 15px;
    }
}
/***********HEADER************/
.accordion__head {
    cursor: pointer;
    display: block;
    position: relative;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    color: $c-default;
    &:before {
        @include pseudo;
        width: 10px;
        height: 10px;
        border-left: 1px solid $c-accord-arrow;
        border-top: 1px solid $c-accord-arrow;
        transform: rotate(135deg);
    }
    &.active {
        &:before {
            transform: rotate(-135deg);
        }
    }
}
.type-1 {
    & > .accordion__head {
        width: 100%;
        padding: 9px 20px 8px 38px;
        border-radius: 5px;
        background-color: $c-bg-disabled;
        &:before {
            top: 14px;
            left: 11px;
        }
    }
    & > .accordion__head.active {
        &:before {
            top: 11px;
            left: 15px;
        }
    }
}
.type-2 {
    & > .accordion__head {
        padding: 0 0 0 26px;
        background-color: $c-transparent;
        &:before {
            top: 4px;
            left: -2px;
        }
    }
    & > .accordion__head.active {
        &:before {
            top: 2px;
            left: 2px;
        }
    }
}
.type-3 {
    & > .accordion__head {
        padding: 0 20px 0 45px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        background-color: $c-transparent;
        &:before {
            top: 6px;
            left: 19px;
        }
    }
    & > .accordion__head.active {
        &:before {
            top: 3px;
            left: 23px;
        }
    }
}
.type-4 {
    position: relative;
    & > .accordion__head {
        padding: 0 0 0 37px;
        background-color: $c-transparent;
        &:before {
            top: 5px;
            left: 12px;
        }
    }
    & > .accordion__head.active {
        &:before {
            top: 2px;
            left: 16px;
        }
    }
}
/*************BODY************/
.accordion__body {
    display: none;
    &.active {
        display: block;
    }
}
.type-1 {
    & > .accordion__body {
        padding: 15px 40px 13px 38px;
    }
}
.type-2 {
    & > .accordion__body {
        padding: 20px 0 6px 30px;
    }
    &:last-child > .accordion__body {
        padding-bottom: 0;
    }
}
.type-3 {
    & > .accordion__body {
        padding: 12px 0 12px 0;
        & > * + * {
            margin-top: 0;
        }
        & > * > * {
            display: block;
            padding: 11px 20px 13px 45px;
            color: $c-default;
            &:not(.current):not(.disabled):hover {
                color: $c-font-active;
            }
            &.current {
                background-color: $c-bg-disabled;
            }
            &.disabled {
                cursor: auto;
                color: $c-disabled;
            }
        }
    }
    &:last-child > .accordion__body {
        padding-bottom: 13px;
    }
}
.type-4 {
    & > .accordion__body {
        padding: 15px 0 5px 33px;
    }
    &:last-child > .accordion__body {
        padding-bottom: 0;
    }
}

/* ********************************************** */
.left-menu {
    .accordion:last-child.active {
        .accordion__body {
            padding-bottom: 0;

            li:last-child {
                a {
                    padding-bottom: 0;
                }
            }
        }
    }
}
/* ********************************************** */
.accordion-head__buttons-wrap {
    position: absolute;
    @include none-size-vertical-center;
    right: 12px;
    font-style: 0;
    line-height: 0;
    .icon-svg-wrap {
        width: 20px;
        height: 20px;
    }

    .icon-svg-wrap + .icon-svg-wrap {
        margin-left: 16px;
    }
}
