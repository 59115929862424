.tab-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 74px;
    height: 74px;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    background-color: transparent;
    & + & {
        margin-left: 38px;
    }
    &.current {
        cursor: auto;
        border-color: $c-font-active;
    }
    label {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    &.disabled {
        cursor: auto;
        label {
            cursor: auto;
        }
    }
}
