.tab-group {
    @include flex-start-stretch;
}
.tab-group--nav {
    /* @include clearfix; */
    justify-content: center;
    /* border-bottom:1px solid #bbd7eb; */
    /* margin-bottom: -1px; */
    margin-left: 35px;
    margin-right: 35px;
}

.tab-wrap .draggable {
    text-align: center;
}
