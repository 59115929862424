/*@import url('color-theme.css');*/
*,
*::after,
*::before {
    box-sizing: border-box;
}
input,
button {
    border: none;
}
input:focus,
button:focus,
:focus {
    outline: none;
}
button {
    cursor: pointer;
}
.touchevents button,
.touchevents a,
.touchevents label,
.touchevents input[type='checkbox'],
.touchevents input[type='radio'] {
    touch-action: manipulation;
}
input[type='checkbox'],
input[type='radio'],
input[type='file'] {
    display: none;
}
a {
    text-decoration: none;
}
p {
    margin: 0;
}
ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}
.clearfix:after {
    content: '';
    display: table;
    width: 100%;
    font-size: 0;
    line-height: 0;
    clear: both;
}
.clear {
    display: table;
    width: 100%;
    font: 0;
    line-height: 0;
    clear: both;
}
html,
body {
    min-width: 100vw;
    overflow-x: hidden;
    @media all and (width <= 320px) {
        min-width: 320px;
    }
}
body {
    position: relative;
    max-width: 100%;
    max-width: 100vw;
    font-size: 18px;
    line-height: 28px;
    @include roboto-condensed-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c-default;
    background-color: $c-body-bg;
    @media all and (width < 1280px) {
        font-size: 17px;
        line-height: 27px;
    }
    @media all and (width < 960px) {
        font-size: 16px;
        line-height: 26px;
    }
    @media all and (width < 640px) {
        font-size: 15px;
        line-height: 24px;
    }
}
body.overlay-open {
    overflow: hidden;
}
.touchevents {
    &,
    * {
        -webkit-tap-highlight-color: transparent;
    }
}
table {
    border-collapse: collapse;
    table-layout: fixed;
}
.grecaptcha-badge {
    display: none;
}
*::-webkit-input-placeholder {
    color: $c-form-item-placeholder;
    opacity: 1;
}
*::-moz-placeholder {
    color: $c-form-item-placeholder;
    opacity: 1;
}
*:-moz-placeholder {
    color: $c-form-item-placeholder;
    opacity: 1;
}
*::-ms-input-placeholder {
    color: $c-form-item-placeholder;
    opacity: 1;
}
*:-ms-input-placeholder {
    color: $c-form-item-placeholder;
    opacity: 1;
}
*::input-placeholder {
    color: $c-form-item-placeholder;
    opacity: 1;
}
*:focus::-webkit-input-placeholder {
    color: transparent !important;
    opacity: 1;
}
*:focus::-moz-placeholder {
    color: transparent !important;
    opacity: 1;
}
*:focus:-moz-placeholder {
    color: transparent !important;
    opacity: 1;
}
*:focus::-ms-input-placeholder {
    color: transparent !important;
    opacity: 1;
}
*:focus:-ms-input-placeholder {
    color: transparent !important;
    opacity: 1;
}
*:focus::input-placeholder {
    color: transparent !important;
    opacity: 1;
}
